import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import AdminDetails from '../Profiles/Admin';
import ClientDetails from '../Profiles/Client';
import StaffDetails from '../Profiles/Staff';
import Admin from './Admin';
import ClientComp from './Client';
import Refferal from './Refferals';
import ServiceProvider from './ServiceProviders';
import ActivityLog from './ActivityLog';
import User from './UserList';
import ManageRoles from './ManageRoles';
import CreateRoles from './CreateRoles';
import Claims from './Claims';
import AdministratorProfile from '../Profiles/AdministratorProfile';
import ServiceProviderClientList from './ServiceProviderClient';
import StaffComp from './Staff';
import DragTest from './DragTest';

const UserManagementLayout = () => {

    return (
        <div>

            <Routes>
                <Route path="/" element={<Navigate to="/app/user/administrators" replace />} />
                <Route path='/all-user' element={<User />} />
                <Route path='/all-roles' element={<ManageRoles />} />
                <Route path='/create-roles' element={<CreateRoles />} />
                <Route path='/administrators' element={<Admin />} />
                <Route path='/staff/:uid/:id' element={<StaffDetails />} />
                <Route path='/administrators/:uid/:id' element={<AdminDetails />} />
                <Route path='/clients/:uid/:id' element={<ClientDetails />} />
                <Route path='/staffs' element={<StaffComp />} />
                <Route path='/clients' element={<ClientComp />} />
                <Route path='/refferals' element={<Refferal />} />
                <Route path='/service-provider' element={<ServiceProvider />} />
                <Route path='/activity-log' element={<ActivityLog />} />
                <Route path='/claims/:uid' element={<Claims />} />
                <Route path='/drag-test' element={<DragTest />} />
                <Route path='/administrator/profile' element={<AdministratorProfile />} />
                <Route path='/provider-client/:uid/:id' element={<ServiceProviderClientList />} />


            </Routes>

        </div>
    )
}

export default UserManagementLayout