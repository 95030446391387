import React from "react";
import { Navigate, Route, Routes } from "react-router";
import PublicHoliday from "./PublicHoliday";
import TimePeriod from "./TimePeriod";
import ThirdParty from "./ThirdParty";
import FormTemplate from "./FormTemplates";
import SupportType from "./SupportType";
import SupportSchedule from "./SupportSchedule";
import CompanyProfile from "./CompanyProfile";
import ChangePassword from "./ChangePassword";
import Registrar from "./Registrar";
import ChangePlan from "./ChangePlan";

const SetupLayout = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/app/setup/public-holiday" replace />}
        />
        <Route path="/public-holiday" element={<PublicHoliday />} />
        <Route path="/time-period" element={<TimePeriod />} />
        <Route path="/integrations" element={<ThirdParty />} />
        <Route path="/form-templates" element={<FormTemplate />} />
        <Route path="/support-type" element={<SupportType />} />
        <Route path="/support-schedule" element={<SupportSchedule />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/registrar" element={<Registrar />} />
        <Route path="/change-plan/:uid" element={<ChangePlan />} />
      </Routes>
    </div>
  );
};

export default SetupLayout;
