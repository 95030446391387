import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import LocationMapModal from "../../../Components/Map/MapModal";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Spinner from "../../../Components/Spinner";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaRegClock } from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  fetchTimeSheet,
  filterTimeSheet,
  timeSheetData,
  timeSheetLoading,
} from "../../../store/slices/timeSheetSlice";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import {
  fetchsplittedAttendance,
  splittedAttendanceData,
  splittedAttendanceLoading,
} from "../../../store/slices/splittedAttendanceSlice";
import { fetchStaff, staffData } from "../../../store/slices/staffSlice";
import CustomInput from "../../../Components/Input/CustomInput";
import {
  fetchStaffTimeSheet,
  staffTimeSheetData,
} from "../../../store/slices/staffTimeSheetSlice";
import {
  allTimeSheetData,
  fetchAllTimeSheet,
} from "../../../store/slices/allTimeSheetSlice";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { claimsTypes } from "../../../types/AuthData";

interface DataRow {
  attendanceId: number;
  clockIn: string;
  clockInCheck: boolean;
  clockOut: string;
  clockOutCheck: boolean;
  companyID: number;
  dateCreated: string;
  dateModified: string;
  duration: number;
  startKm: number;
  endKm: number;
  imageFile: string | null;
  imageURL: string | null;
  inLatitude: number;
  inLongitude: number;
  isSplitted: boolean;
  outLatitude: number;
  outLongitude: number;
  report: string;
  staff: StaffData;
}

interface StaffData {
  email: string;
  employeeId: string | null;
  firstName: string;
  fullName: string;
  gender: string;
  phoneNumber: string;
  staffId: number;
}

function formatDuration(duration: number | null | undefined): string {
  if (duration) {
    const durationInMilliseconds: number = duration / 10000; // Convert ticks to milliseconds

    const durationInMinutes: number = Math.floor(
      durationInMilliseconds / (1000 * 60)
    );
    const hours: number = Math.floor(durationInMinutes / 60);
    const minutes: number = durationInMinutes % 60;

    return `${hours} Hrs ${minutes} min`;
  }

  return "0 Hrs 0 min"; // Return an empty string if duration is not available
}

const Timesheet = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [users, setUsers] = useState<string>("");
  const [adjustModal, setAjustModal] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editDetailsModal, setEditDetailsModal] = useState<boolean>(false);
  const [loadingClockId, setLoadingClockId] = useState<number | null>(null);
  const [saveId, setSaveId] = useState<number>(0);
  const handleClockClick = (attendanceId: number) => {
    setSaveId(attendanceId);
    setAdjustTimeModal(true);
  };
  const staffClaimsString = localStorage.getItem("claims");
  const claims: claimsTypes[] = staffClaimsString
    ? JSON.parse(staffClaimsString)
    : [];
  const hasRequiredClaims = (claimType: string): boolean => {
    return claims.some((claim) => claim.value === claimType);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Adjust Attendance",
      cell: (row: DataRow) => (
        <div className="flex gap-4  justify-center ">
          {user?.role === "CompanyAdmin" ||
          user?.role === "Administrator" ||
          hasRequiredClaims("Adjust Attendances") ? (
            <button
              title="Adjust Attendance"
              onClick={() => handleClockClick(row.attendanceId)}
              disabled={loadingClockId === row.attendanceId}
              className="w-full md:w-auto flex items-center justify-center py-1 px-3 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              type="button"
            >
              {loadingClockId === row.attendanceId ? (
                <Spinner />
              ) : (
                <FaRegClock className="text-base" />
              )}
            </button>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "Staff",
      selector: (row) => row.staff.fullName,
      sortable: true,
      cell: (row) => (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={<Tooltip>{"Click to view splitted attendance"}</Tooltip>}
        >
          <span
            className="truncate font-bold"
            style={{ overflow: "hidden", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title={""}
            onClick={() => handleSplitted(row.attendanceId)}
          >
            {row.staff.fullName}
          </span>
        </Whisper>
      ),
    },

    {
      name: "Clock-In",
      selector: (row) => dayjs(row.clockIn).format("DD/MM/YYYY h:mm A"),
    },
    {
      name: "Duration",
      selector: (row) => formatDuration(row.duration),
    },
    {
      name: "Clock-Out",
      cell: (row) => (
        <span style={{ overflow: "hidden" }}>
          {!row.clockOut ? (
            <span className="bg-yellow-500  py-1 px-2 rounded">In Shift</span>
          ) : (
            dayjs(row.clockOut).format("DD/MM/YYYY h:mm A")
          )}
        </span>
      ),
    },
    {
      name: "Location",
      cell: (row) => (
        <span style={{ overflow: "hidden" }}>
          {row.inLatitude === 0 && row.inLongitude === 0 ? (
            <small className="cursor-none px-2 py-1 text-xs bg-red-500 text-white rounded flex justify-center items-center">
              No Location
            </small>
          ) : (
            <LocationMapModal
              latitude={row.inLatitude}
              longitude={row.inLongitude}
            />
          )}
        </span>
      ),
    },
  ];

  const [offerLetter, setOfferLetter] = useState<File | null>(null);
  const [splittedModal, setSplittedModal] = useState<boolean>(false);
  const [adjustTimeModal, setAdjustTimeModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchTimeSheet(companyId));
      dispatch(fetchStaff(companyId));
    }
  }, [dispatch, companyId]);
  const timeSheet = useSelector(timeSheetData);
  const userTimeSheet = useSelector(staffTimeSheetData);
  const allTimeSheet = useSelector(allTimeSheetData);
  const loadingData = useSelector(timeSheetLoading);
  const staff = useSelector(staffData);
  const splittedAttendance = useSelector(splittedAttendanceData);
  const spittedAttendanceLoading = useSelector(splittedAttendanceLoading);
  const [attendanceDetails, setAttendanceDetails] = useState<DataRow>({
    attendanceId: 0,
    clockIn: "",
    clockInCheck: false,
    clockOut: "",
    clockOutCheck: false,
    companyID: 0,
    dateCreated: "",
    dateModified: "",
    duration: 0,
    startKm: 0,
    endKm: 0,
    imageFile: "",
    imageURL: "",
    inLatitude: 0,
    inLongitude: 0,
    isSplitted: false,
    outLatitude: 0,
    outLongitude: 0,
    report: "",
    staff: {
      email: "",
      employeeId: "",
      firstName: "",
      fullName: "",
      gender: "",
      phoneNumber: "",
      staffId: 0,
    },
  });

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Staff: </span>
          <span> {data.staff.fullName}</span>
        </span>
        <span>
          <span className="font-bold">Date Created: </span>
          <span>{dayjs(data.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <span>
          <span className="font-bold">Date Modified: </span>
          <span>{dayjs(data.dateModified).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <div className="flex gap-2">
          <span className="font-bold">Actions: </span>
          {user?.role === "CompanyAdmin" ||
          user?.role === "Administrator" ||
          hasRequiredClaims("Edit Attendances") ? (
            <button
              className="btn text-primary font-bold"
              style={{ fontSize: "12px" }}
              onClick={() => EditDetail(data.attendanceId)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          |
          <button
            className="btn text-primary font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => handleDetails(data.attendanceId)}
          >
            Details
          </button>
        </div>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = timeSheet?.filter((item) =>
    item?.staff?.fullName?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleTimeAdjustment = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setLoadingClockId(saveId);
    try {
      const { data } = await axiosInstance.get(
        `/attendances/adjust_attendances?userId=${user?.uid}&attendanceId=${saveId}`
      );
      toast.success(data.message);
      setAdjustTimeModal(false);
      setLoadingClockId(null);
      companyId && dispatch(fetchTimeSheet(companyId));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setAdjustTimeModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSplitted = (e: number) => {
    dispatch(fetchsplittedAttendance(e));
    setSplittedModal(true);
  };

  const [periodic, setPeriodic] = useState<DataRow[]>([]);
  const filterActivity = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (companyId && dateFrom && dateTo) {
      dispatch(
        filterTimeSheet({
          company: companyId,
          fromDate: dateFrom,
          toDate: dateTo,
          staff: users,
        })
      );
      setPeriodic(timeSheet);
    }
    setLoading(false);
  };

  const GetUserTimeSheet = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    if (user?.uid && users && dateFrom && dateTo) {
      dispatch(
        fetchStaffTimeSheet({
          dateFrom: dateFrom,
          dateTo: dateTo,
          staff: users,
          user: user?.uid,
        })
      );
    }
    setLoading1(false);
    setTimeout(() => {
      const url = `/pdf/staff-timesheet/${users}/${dateFrom}/${dateTo}`;
      window.open(url, "_blank");
      setLoading1(false);
    }, 2000);
  };

  const GetAllTimeSheet = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    if (user?.uid && dateFrom && dateTo) {
      dispatch(
        fetchAllTimeSheet({
          dateFrom: dateFrom,
          dateTo: dateTo,
          user: user?.uid,
        })
      );
    }
    setLoading1(false);
    setTimeout(() => {
      const url = `/pdf/all-staff-timesheet/${dateFrom}/${dateTo}`;
      window.open(url, "_blank");
      setLoading1(false);
    }, 2000);
  };

  const handleDetails = async (id: number) => {
    setLoading(true);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/Attendances/${id}`);
      setAttendanceDetails({ ...data });
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
        setLoading(false);
      } else {
        toast.error("An error occurred");
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const EditDetail = async (id: number) => {
    setLoading(true);
    setSaveId(id);
    setEditDetailsModal(true);
    try {
      const { data } = await axiosInstance.get(`/Attendances/${id}`);
      setAttendanceDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setAttendanceDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setOfferLetter(file);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);

    const info = {
      ...attendanceDetails,
      imageFile: offerLetter && offerLetter,
    };

    try {
      const { data } = await axiosInstance.post(
        `/Attendances/edit/${saveId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      companyId && dispatch(fetchTimeSheet(companyId));
      setEditDetailsModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const SendTimesheet = async (e: FormEvent) => {
    e.preventDefault();
    setLoading2(true);

    try {
      const { data } = await axiosInstance.get(
        `/ShiftRosters/send_timesheet?userId=${user?.uid}&fromDate=${dateFrom}&toDate=${dateTo}&staffId=${users}&companyId=${companyId}`
      );
      toast.success(data.message);
      setLoading2(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      }
      setLoading2(false);
    } finally {
      setLoading2(false);
    }
  };

  const sendTimesheetToAll = async (e: FormEvent) => {
    e.preventDefault();
    setLoading2(true);

    try {
      const { data } = await axiosInstance.get(
        `/ShiftRosters/send_all_timesheet?userId=${user?.uid}&fromDate=${dateFrom}&toDate=${dateTo}&companyId=${companyId}`
      );
      toast.success(data.message);
      setLoading2(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      }
      setLoading2(false);
    } finally {
      setLoading2(false);
    }
  };
  const AdjustAttendanceDate = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Attendances/adjust_periodic_attendance?userId=${user?.uid}&shiftdatefrom=${dateFrom}&shiftdateto=${dateTo}&companyId=${companyId}&staffId=${users}`
      );
      toast.success(data.message);
      setLoading(false);
      setAjustModal(false);
      // filterActivity(e)
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO
        title="Attendance Reports"
        description="Attendance Reports"
        name="Promax-Care"
      />
      <Breadcrumb />

      <div className="mt-10">
        <div>
          <form
            action=""
            onSubmit={filterActivity}
            className="space-y-1 p-4  items-center shadow-md rounded-md lg:gap-8 bg-white mb-4"
          >
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
              <div>
                <label className=" mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">
                  Select Staff
                </label>
                <div className="relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded">
                  <select
                    className="border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5"
                    onChange={(e) => setUsers(e.target.value)}
                  >
                    <option value={""}>All Staff</option>
                    {staff.map((data, index) => (
                      <option value={data.staffId} key={index}>
                        {data.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <CustomInput
                label="Start Date"
                type="datetime-local"
                name="dateFrom"
                onChange={(e) => setDateFrom(e.target.value)}
                // ref={dateFrom}
              />
              <CustomInput
                label="End Date"
                type="datetime-local"
                name="dateTo"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap">
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2.5 me-2 mt-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm"
              >
                {loading ? <Spinner /> : "Load"}
              </button>

              <div>
                {users === "" || periodic.length <= 0 || loadingData ? (
                  ""
                ) : (
                  <div>
                    <button
                      onClick={SendTimesheet}
                      disabled={loading2}
                      className="px-6 py-2.5 me-2 mt-2 hover:text-white hover:no-underline bg-gray-500 text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-gray-600 font-semibold rounded text-sm"
                    >
                      {loading2 ? <Spinner /> : "Send Timesheet to staff"}
                    </button>

                    {user?.role === "CompanyAdmin" ||
                    user?.role === "Administrator" ||
                    hasRequiredClaims("Generate Timesheet") ? (
                      <button
                        onClick={GetUserTimeSheet}
                        disabled={loading1}
                        className="px-6 py-2.5 me-2 mt-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm"
                      >
                        {loading1 ? <Spinner /> : "Generate Timesheet "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>

              <div>
                {users !== "" || periodic.length <= 0 || loadingData ? (
                  ""
                ) : (
                  <div>
                    <button
                      onClick={sendTimesheetToAll}
                      disabled={loading2}
                      className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary"
                    >
                      {loading2 ? <Spinner /> : "Send Timesheet to all staff"}
                    </button>

                    <button
                      onClick={GetAllTimeSheet}
                      disabled={loading1}
                      className="px-6 py-2.5 me-2 mt-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm"
                    >
                      {loading1 ? (
                        <Spinner />
                      ) : (
                        "Generate Timesheet for all staff"
                      )}
                    </button>
                  </div>
                )}
              </div>

              <div>
                {dateFrom !== "" &&
                dateTo !== "" &&
                periodic.length > 0 &&
                !loadingData ? (
                  <div>
                    <button
                      type="button"
                      onClick={() => setAjustModal(true)}
                      disabled={loading}
                      className="text-black bg-secondary hover:bg-secondary focus:ring-4 focus:ring-secondary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-secondary dark:hover:bg-secondary focus:outline-none
dark:focus:ring-secondary"
                    >
                      {loading ? <Spinner /> : "Adjust Attendance"}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                {userTimeSheet?.xeroUploadLink && !loading && users !== "" && (
                  <div>
                    <a
                      href={userTimeSheet.xeroUploadLink}
                      type="submit"
                      target="_blank"
                      rel="noreferrer"
                      className="text-white bg-green-500 hover:bg-green-500 focus:ring-4 focus:ring-green-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-green-500 dark:hover:bg-green-500 focus:outline-none hover:no-underline hover:text-white
dark:focus:ring-primary"
                    >
                      Post Staff Timesheet to Xero
                    </a>
                  </div>
                )}
              </div>
              <div>
                {allTimeSheet?.xeroUploadLink && !loading && (
                  <div>
                    <a
                      href={allTimeSheet.xeroUploadLink}
                      type="submit"
                      target="_blank"
                      rel="noreferrer"
                      className="text-white bg-green-500 hover:bg-green-500 focus:ring-4 focus:ring-green-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-green-500 dark:hover:bg-green-500 focus:outline-none hover:no-underline hover:text-white
dark:focus:ring-primary"
                    >
                      Post All Staff Timesheet to Xero
                    </a>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        {loadingData && <Spinner color="#071952" />}

        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4 mt-10">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">Timesheet</h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Staff Name"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button onClick={() => setFilterModal(true)} id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        timeSheet,
                        columns as unknown as Column<DataRow>[],
                        "Timesheet.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        timeSheet,
                        columns as unknown as Column<DataRow>[],
                        "Timesheet"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: timeSheet,
                        filename: "Timesheet.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={timeSheet?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={adjustModal}
        onClose={() => setAjustModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Adjust Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will adjust all attendances within the specified date in
              relation to the Shifts, Fix Public holidays anomalies, re-adjust
              the clock out time to shift's end time & Properly split
              attendances into the actual time periods. Do you wish to Proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setAjustModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={AdjustAttendanceDate}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={adjustTimeModal}
        onClose={() => setAdjustTimeModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              The selected attendance will be adjusted to the exact time of the
              shift duration. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setAdjustTimeModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                type="submit"
                onClick={handleTimeAdjustment}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={splittedModal}
        onClose={() => setSplittedModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"md"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Splitted Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
            <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
              <tr>
                <th scope="col">Clock In</th>
                <th scope="col">Duration</th>
                <th scope="col">Clock Out</th>
                <th scope="col">Km</th>
                <th scope="col">Shift</th>
              </tr>
            </thead>
            <tbody>
              {spittedAttendanceLoading ? (
                <tr className="border-b border-neutral-200 dark:border-white/10">
                  <td className="whitespace-nowrap py-4"></td>
                  <td className="whitespace-nowrap py-4"></td>
                  <td className="whitespace-nowrap py-4">
                    {" "}
                    <Spinner />{" "}
                  </td>
                  <td className="whitespace-nowrap py-4"></td>
                  <td className="whitespace-nowrap py-4"></td>
                </tr>
              ) : (
                splittedAttendance.map((data, index) => (
                  <tr
                    className="border-b border-neutral-200 dark:border-white/10"
                    key={index}
                  >
                    <td className="whitespace-nowrap py-4 font-medium">
                      {dayjs(data.clockIn).format("DD/MM/YYYY h:mm A")}
                    </td>
                    <td className="whitespace-nowrap py-4">
                      {formatDuration(data.duration)}
                    </td>
                    <td className="whitespace-nowrap py-4">
                      {dayjs(data.clockOut).format("DD/MM/YYYY h:mm A")}
                    </td>
                    <td className="whitespace-nowrap py-4">{data.totalKm}</td>
                    <td className="whitespace-nowrap py-4">
                      <small
                        style={{ fontSize: "12px" }}
                        className={`px-2 py-1 rounded text-white bg-${
                          data.shift === "M"
                            ? "primary"
                            : data.shift === "E"
                            ? "secondary"
                            : data.shift === "N"
                            ? "gray-500"
                            : "primary"
                        }
                          `}
                      >
                        {data.shift === "M"
                          ? "Normal"
                          : data.shift === "E"
                          ? "Evening"
                          : data.shift === "N"
                          ? "Sleepover"
                          : data.shift === "SU"
                          ? "Sunday"
                          : data.shift === "PH"
                          ? "Public Holiday"
                          : data.shift === "SUN"
                          ? "Sunday Night"
                          : data.shift === "SAN"
                          ? "Saturday Night"
                          : data.shift === "EX"
                          ? "Active Night"
                          : data.shift === "SA"
                          ? "Saturday"
                          : data.shift}
                      </small>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* </div>
                            </div>
                        </div>
                    </div> */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editDetailsModal}
        onClose={() => setEditDetailsModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <form action="">
                <CustomInput
                  label="Staff Name"
                  type="text"
                  value={attendanceDetails.staff.fullName}
                  readOnly
                />
                <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                  <CustomInput
                    label="Clock In"
                    type="datetime-local"
                    name="clockIn"
                    value={attendanceDetails.clockIn || ""}
                    onChange={handleInputChange}
                  />

                  <CustomInput
                    label="Clock Out"
                    type="datetime-local"
                    name="clockOut"
                    value={attendanceDetails.clockOut || ""}
                    onChange={handleInputChange}
                  />

                  <CustomInput
                    label="Starting Kilometre (km)"
                    type="number"
                    name="startKm"
                    value={attendanceDetails.startKm || ""}
                    onChange={handleInputChange}
                  />

                  <CustomInput
                    label="Ending Kilometre (km)"
                    type="number"
                    name="endKm"
                    value={attendanceDetails.endKm || ""}
                    onChange={handleInputChange}
                  />

                  <div className="lg:col-span-2">
                    <label htmlFor="report" className="font-bold">
                      Additional Report{" "}
                      <span
                        className="text-green-300"
                        style={{ fontSize: "9px" }}
                      >
                        This could be reasons why you were late or information
                        you want your admin to be aware of
                      </span>
                    </label>
                    <textarea
                      id="report"
                      rows={4}
                      name="report"
                      autoComplete="off"
                      value={attendanceDetails.report || ""}
                      onChange={handleInputChange}
                      className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                      defaultValue={""}
                    />
                  </div>

                  <div className="lg:col-span-2">
                    <CustomInput
                      label="Image"
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={handleFileChange}
                      placeholder="Image"
                    />
                  </div>
                </div>
              </form>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Timesheet Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div className="">
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Clock In:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(attendanceDetails.clockIn).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Clock Out:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {!attendanceDetails.clockOut ? (
                    <span className="bg-yellow-500 py-1 px-2 rounded text-black">
                      In Shift
                    </span>
                  ) : (
                    dayjs(attendanceDetails.clockOut).format(
                      "MMMM D, YYYY h:mm A"
                    )
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Duration:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {formatDuration(attendanceDetails.duration)}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Kilometre:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {attendanceDetails.endKm - attendanceDetails.startKm} Km
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Report:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {attendanceDetails.report}
                </td>
              </tr>
              {attendanceDetails.imageURL && (
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Image:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {" "}
                    <div style={{ width: "300px", height: "200px" }}>
                      <img
                        src={attendanceDetails.imageURL}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* {
                                attendanceDetails.imageUrl && (
                                    <p>
                                        <strong>Image:</strong>  <div style={{ width: "300px", height: "200px" }}><img src={attendanceDetails.imageUrl} alt="" width={"100%"} /></div>
                                    </p>
                                )
                            } */}
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {dayjs(attendanceDetails.dateCreated).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Timesheet;
