import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import Hero from "./__component/Hero";
import PlanForm from "./__component/PlanForm";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";
import Spinner from "../../Components/Spinner";
import { fetchClientInfo, fetchCompany, fetchParticipantChecklistdoc } from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Client } from "../../store/slices/clientSlice";
import { useParams } from "react-router-dom";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Date from "./__component/Date";
import { ParticipantCheckList } from "../../types/DataType";
import EditParticipantChecklist from "./EditParticipantCheckList";

export interface Checkboxes {
  [key: string]: boolean;
}

export interface Plan {
  name: string;
  checked: boolean;
}

const ParticipantChecklist = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
  }, [uid])

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const dateChecked = useRef<HTMLInputElement>(null);
  const staffName = useRef<HTMLTableCellElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const [checkboxes, setCheckboxes] = useState<Checkboxes>({
    sign: false,
    consent: false,
    support: false,
    safety: false,
    manage: false,
    chart: false,
    plan: false
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setCheckboxes(prevState => ({
      ...prevState,
      [id]: checked
    }));
  };

  const [plans, setPlans] = useState<Plan[]>([
    { name: "", checked: false },
    { name: "", checked: false },
    { name: "", checked: false },
    { name: "", checked: false },
    { name: "", checked: false },
  ]);

  const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newPlans = [...plans];
    newPlans[index].name = event.target.value;
    setPlans(newPlans);
  };

  const handlePlanCheckboxChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newPlans = [...plans];
    newPlans[index].checked = event.target.checked;
    setPlans(newPlans);
  };

  const [checker, setChecker] = useState<ParticipantCheckList | null>(null)

  useEffect(() => {
    const fetchDoc = async () => {
      const data = await fetchParticipantChecklistdoc(
        Number(uid),
        Number(user?.companyId),
        "PCD-102 P03 Participant file checklist"
      );
      setChecker(data)
    };

    fetchDoc();
  }, [uid, user]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;

    const info = {
      staff_Member: staffName.current?.innerText,
      service_Argreement: checkboxes.sign,
      consent_Form: checkboxes.consent,
      support_plan: checkboxes.support,
      safety_plan: checkboxes.safety,
      medication_Plan: checkboxes.manage,
      medication_Chart: checkboxes.chart,
      other_plan: checkboxes.plan,
      plan_1: plans[0].name,
      check_Plan_1: plans[0].checked,
      plan_2: plans[1].name,
      check_Plan_2: plans[1].checked,
      plan_3: plans[2].name,
      check_Plan_3: plans[2].checked,
      plan_4: plans[3].name,
      check_Plan_4: plans[3].checked,
      plan_5: plans[4].name,
      check_Plan_5: plans[4].checked,
      profileId: uid,
      document_Name: "PCD-102 P03 Participant file checklist",
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      companyId: user?.companyId
    }


    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/ParticipantFileChecklists/fill_checklist?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditParticipantChecklist
          companyOne={companyOne}
          clientDetail={clientDetail}
        />
      ) : (

        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print this Document"
            documentTitle="Participant Checklist"
          />
          <div ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero clientDetail={clientDetail} companyOne={companyOne}
              dateChecked={dateChecked}
              staffName={staffName}
              handleCheckboxChange={handleCheckboxChange}
              checkboxes={checkboxes}
            />
            <PlanForm handleInputChange={handleInputChange} handlePlanCheckboxChange={handlePlanCheckboxChange} plans={plans} />

            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
          </div>

          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ParticipantChecklist;
