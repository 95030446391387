// import React from "react";
// import { Client } from "../../../store/slices/clientSlice";
// import { SupportGoalPlanData } from "../../../types/DataType";

// interface Props {
//     clientDetail: Client | null;
//     detatails: SupportGoalPlanData;
//     handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
// }

// const EditGoalForm = ({ clientDetail }: Props) => {

//     return (
//         <form >
//             <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300 w-full">
//                 <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
//                     <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
//                         <tr className="divide-x-2">
//                             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//                                 Participant details
//                             </th>
//                             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900"></th>
//                             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//                                 Meeting date:
//                             </th>
//                         </tr>
//                     </thead>

//                     <tbody className="divide-y divide-gray-200">
//                         <tr className="divide-x-2">
//                             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//                                 Participant name:
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
//                                 {clientDetail?.fullName}
//                             </td>
//                             {/* <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
//                 <p>Attendees: </p>
//                 <ol className="list-decimal p-4 space-y-2">
//                   {attendees.map((attendee, index) => (
//                     <li key={index}>
//                       <input
//                         className="w-full border border-gray-300 outline-none rounded p-1.5"
//                         type="text"
//                         value={attendee}
//                         onChange={(event) => handleInputChange(index, event)}
//                       />
//                     </li>
//                   ))}
//                 </ol>
//               </td> */}
//                         </tr>
//                         <tr className="divide-x-2">
//                             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//                                 Date of birth:
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
//                                 {clientDetail?.dateOfBirth}
//                             </td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </form>
//     );
// };

// export default EditGoalForm;

import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import { SupportGoalPlanData } from "../../../types/DataType";

interface Props {
    clientDetail: Client | null;
    details: SupportGoalPlanData;
    handleInputDChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditGoalForm = ({ clientDetail, details, handleInputDChange }: Props) => {
    const attendees = details.attendees.split(', '); // Split the comma-separated string into an array

    return (
        <form>
            <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300 w-full">
                <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                        <tr className="divide-x-2">
                            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                                Participant details
                            </th>
                            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900"></th>
                            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                                Meeting date:
                            </th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200">
                        <tr className="divide-x-2">
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                Participant name:
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                                {clientDetail?.fullName}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                                <p>Attendees: </p>
                                <ol className="list-decimal p-4 space-y-2">
                                    {attendees.map((attendee, index) => (
                                        <li key={index}>
                                            <input
                                                className="w-full border border-gray-300 outline-none rounded p-1.5"
                                                type="text"
                                                value={attendee}
                                                onChange={(event) => handleInputDChange(index, event)}
                                            />
                                        </li>
                                    ))}
                                </ol>
                            </td>
                        </tr>
                        <tr className="divide-x-2">
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                Date of birth:
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                                {clientDetail?.dateOfBirth}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default EditGoalForm;
