import React, { useRef } from "react";
import Hero from "./___component/Hero";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { SupportGoalPlanData } from "../../types/DataType";
import EditGoalForm from "./___component/EditGoalForm";
import EditSection1 from "./___component/EditSection1";
import EditResponsibilityTable from "./___component/EditResponsibilityTable";
import EditProgress from "./___component/EditProgress";
import EditDate from "./___component/EditDate";

interface Row {
    date: string;
    actionRef: string;
    progressUpdate: string;
}

type RowKeys = "date" | "actionRef" | "progressUpdate";

interface Props {
    companyOne: CompanyDetail | null | undefined;
    clientDetail: Client | null;
    details: SupportGoalPlanData;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleInputDChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    actions: string[];
    responsibilities: string[];
    timeframes: string[];
    handleActionChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleResponsibilityChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTimeframeChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    rows: Row[];
    handleCellEditChange: (rowIndex: number, cellName: RowKeys, value: string) => void;

}
const EditSupportGoal = ({ companyOne, clientDetail, handleInputDataChange, handleInputDChange, details,
    actions, handleActionChange, handleResponsibilityChange, handleTimeframeChange, responsibilities, timeframes,
    handleCellEditChange, rows
}: Props) => {

    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <div>

            <form
            //   onSubmit={handleSubmit}
            >
                <PrintComponent
                    componentRef={componentRef}
                    triggerLabel="Print this Document"
                    documentTitle="Consent Form"
                />
                <div
                    ref={componentRef}
                    className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
                >
                    <Hero companyOne={companyOne} />
                    <EditGoalForm clientDetail={clientDetail} details={details} handleInputDChange={handleInputDChange} />
                    <EditSection1 details={details} handleInputDChange={handleInputDChange} handleInputDataChange={handleInputDataChange}
                    />
                    <EditResponsibilityTable actions={actions} handleActionChange={handleActionChange} handleResponsibilityChange={handleResponsibilityChange}
                        handleTimeframeChange={handleTimeframeChange} responsibilities={responsibilities} timeframes={timeframes}
                    />
                    <EditProgress handleCellEditChange={handleCellEditChange} rows={rows} />
                    <EditDate
                        handleInputDataChange={handleInputDataChange}
                        details={details}
                    />
                </div>
                <div className="mx-auto text-center mt-3 lg:col-span-2">
                    <button
                        type="submit"
                        // disabled={loading}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
                    >
                        {/* {loading ? <Spinner /> : " Submit"} */}
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditSupportGoal;
