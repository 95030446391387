import React, { ChangeEvent, useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import dayjs from 'dayjs';
import Spinner from '../../../../Components/Spinner';
import moment from 'moment';
import { fetchStaffAvailability, staffAvailabilityData, staffAvailabilityLoading } from '../../../../store/slices/staffAvailabilitySlice';

interface DataRow {
    companyID: number;
    dateCreated: string;
    dateModified: string;
    days: string;
    fromTimeOfDay: string;
    staffAvailibilityId: number;
    staffId: number;
    toTimeOfDay: string;
    userCreated: string;
    userModified: string;
    staff: {
        staffId: number;
        maxStaffId: string;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
        dateCreated: string;
        userCreated: string;
        dateModified: string;
        userModified: string;
    };
}

// function convertTo12HourFormat(time24h: string): string {
//     let [initialHours, minutes] = time24h.split(':').map(Number);  // Convert hours and minutes to numbers
//     let hours = initialHours;
//     let suffix = 'AM';

//     if (hours >= 12) {
//         suffix = 'PM';
//         if (hours > 12) { // Note: If it is exactly 12, it should still be PM but not subtract 12
//             hours -= 12;
//         }
//     }

//     if (hours === 0) {
//         hours = 12;  // midnight case
//     }

//     return `${hours}:${minutes} ${suffix}`;
// }


function convertTo12HourFormat(time24h: string): string {
    const [initialHours, minutes] = time24h.split(':').map(Number); // Convert hours and minutes to numbers
    let hours = initialHours;
    let suffix = 'AM';

    if (hours >= 12) {
        suffix = 'PM';
        hours = hours - 12;
    }

    if (hours === 0) {
        hours = 12;
    }

    return `${hours}:${minutes} ${suffix}`;
}


interface StaffSchedulerops {
    uid: string; // Define the type of uid as string
}


const Schedule: React.FC<StaffSchedulerops> = ({ uid }) => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Days',
            selector: row => row.days,
            sortable: true,
        },
        {
            name: 'From Time of Day',
            selector: row => convertTo12HourFormat(row.fromTimeOfDay),
            sortable: true,
        },
        {
            name: 'To Time of Day',
            selector: row => convertTo12HourFormat(row.toTimeOfDay),
            sortable: true
        },
        {
            name: 'Date Created',
            selector: row => dayjs(row.dateCreated).format('DD/MM/YYYY HH:mm:ss'),
            sortable: true
        }


    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchStaffAvailability(Number(uid)));
        }
    }, [dispatch, companyId, uid]);
    const staffSchedule = useSelector(staffAvailabilityData);
    const loadingData = useSelector(staffAvailabilityLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = staffSchedule?.filter((item) =>
        item?.staff?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }


    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div><span className='font-bold'>Date Modified: </span>{moment(data.dateModified).format('lll')}</div>
                <div><span className='font-bold'>Date Created: </span>{moment(data.dateCreated).format('lll')}</div>

            </div>
        )
    }


    return (
        <div className='mt-10'>
            {loadingData && <Spinner color='#071952' />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>Schedule</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                    <div className="flex items-center space-x-3 w-full md:w-auto">
                        <ExportDropdown >
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className='text-red-500' />}
                                    title="Export as PDF"
                                    onClick={() => handlePDFDownload(staffSchedule, columns as unknown as Column<DataRow>[], "Schedule.pdf")}

                                />
                                <IconButton
                                    icon={<FaFileExcel className='text-green-600' />}
                                    title="Export as Excel Sheet"

                                    onClick={() => exportToExcel(staffSchedule, columns as unknown as Column<DataRow>[], "Schedule")}

                                />
                                <IconButton
                                    icon={<FaFileCsv className='text-green-500' />}
                                    title="Export as CSV"
                                    onClick={() => downloadCSV({ data: staffSchedule, filename: 'Schedule.csv' })}

                                />
                            </div>
                        </ExportDropdown>


                    </div>


                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={staffSchedule?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

            />

        </div>
    )
}

export default Schedule