import React from 'react'

const FirstForm = () => {
  return (
    <div>
        <table className="w-full mb-6">
        <tbody>
          <tr>
            <td className="border px-4 py-2 font-bold">Date:</td>
            <td className="border px-4 py-2"><input type="date" className="w-full p-2 border border-gray-300 rounded" /></td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Meeting Attendees:</td>
            <td className="border px-4 py-2">
              {[...Array(4)].map((_, i) => (
                <input key={i} type="text" className="w-full p-2 mb-2 border border-gray-300 rounded" placeholder={`Attendee ${i + 1}`} />
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default FirstForm