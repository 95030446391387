import React, { useEffect, useState } from 'react'
import icon from '../../../images/icon/premium.svg';
import icon2 from '../../../images/icon/enterprise.svg';
import icon3 from '../../../images/icon/proffessional.svg';
// import { useParams } from 'react-router-dom';
import { CompanyDetail, PackageProps } from '../../../types/AuthData';
import { fetchCompany, changeCompanyPackage, fetchPackages } from '../../../services/fetchServices';
import SEO from '../../../constants/SEO';
import PricingControl from '../../../Components/Pricing/PricingControl';
import PricingCard from '../../../Components/Pricing/PricingCard';
import PricingFeatures from '../../../Components/Pricing/PricingFeatures';
import PriceButton from '../../../Components/Pricing/PricingButton';
import Spinner from '../../../Components/Spinner';
import { FaArrowRightLong } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';

const ChangePlan = () => {
    const [activeTab, setActiveTab] = useState('monthly');
    // const navigate = useNavigate();
    const [packages, setPackages] = useState<PackageProps[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPackageId, setLoadingPackageId] = useState<number | null>(null);
    const [companyOne, setCompanyOne] = useState<CompanyDetail | null>(null)
    const { uid } = useParams()
    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const handleCompanyPlan = async (id: number) => {
        setLoadingPackageId(id);
        try {
            const data = await changeCompanyPackage(Number(uid), id);
            toast.success(data.message);
            setLoadingPackageId(null);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoadingPackageId(null);
        } finally {
            setLoadingPackageId(null);
        }

    }

    const handleFetch = async () => {
        const data = await fetchPackages();
        setPackages(data)
        setLoading(false)
    }

    const handleCompanyFetch = async () => {
        // setLoading(true);
        if (uid) {
            const data = await fetchCompany(Number(uid));
            setCompanyOne({ ...data });
        }
        // setLoading(false);
    };

    useEffect(() => {
        handleFetch();
        handleCompanyFetch();
    }, [uid]);


    // const Goto = (e: number) => {

    //     if (comp) {
    //         localStorage.setItem("companyId", comp);
    //         localStorage.setItem("packageId", e.toString());
    //     }
    //     navigate(`/setup`, { replace: true });
    // }


    if (loading) {
        return <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-lg mx-auto">
                <div
                    className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                >
                    <Spinner color="#071952" />
                </div>
            </div>

            <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
    }


    return (
        <section className="bg-white dark:bg-gray-900">
            <SEO
                title='Subscription - Promax Care'
                description='Select your subscription package'
                name='Promax-Care'
            />
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-4">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-8">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">Upgrade to ProMax Care Premium</h2>
                    <p className="mb-5 font-light text-gray-500 sm:text-sm dark:text-gray-400">Unlock advanced features and maximize your HR management experience.</p>
                </div>
                <div className='mx-auto mb-8 lg:mb-8 text-center'>
                    <PricingControl activeTab={activeTab} handleTabChange={handleTabChange} />
                </div>


                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-4 xl:gap-4 lg:space-y-0 ">

                    {

                        packages?.length > 0 && packages.map((d, i) =>
                            <>
                                <PricingCard
                                    bg={d.packagesId === 3 ? "primary" : ""}
                                    col={d.packagesId === 3 ? "white" : ""}
                                    key={i}
                                    icon={d.packagesId === 4 ? icon3 : d.packagesId === 3 ? icon2 : icon}
                                    title={d.package}
                                    subtext={d.packageDetails}
                                    price={activeTab === 'yearly' ? (d.amount * 12) : d.amount}
                                    duration={activeTab === 'yearly' ? 'year' : 'month'}
                                >
                                    {companyOne?.packages.package === d.package && (
                                        <div className="mb-2 text-base font-bold text-secondary">
                                            Current Plan
                                        </div>
                                    )}

                                    {d.features.split(/,\s*/).map((feature, index) => (
                                        <PricingFeatures
                                            key={index}
                                            text={<span>
                                                {feature}
                                            </span>}

                                        />
                                    ))}

                                    <PriceButton
                                        className={`text-[#4B4EFC] ${d.packagesId === 3 ? "bg-secondary" : "bg-gray-200"}`}
                                        disabled
                                    >
                                        {`${d.packagesId === 2 ? "Start for free" : "Buy Now"}`}
                                    </PriceButton>
                                    <button
                                        onClick={() => handleCompanyPlan(d.packagesId)}
                                        className={`w-full hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap
    focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand hover:bg-brand
    text-center dark:text-white  dark:focus:ring-primary-900 ${d.packagesId === 3 ? "text-white" : "text-[#4B4EFC] hover:text-white"} `}>
                                        {loadingPackageId === d.packagesId ? <Spinner /> : <>Select <FaArrowRightLong /></>}
                                    </button>
                                </PricingCard>
                            </>


                        )
                    }



                </div>
            </div>
        </section>

    )
}

export default ChangePlan