import React from "react";

const ServiceTable = () => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
          <tr className="divide-x-2">
            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900"></th>
            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Time/s
            </th>
            <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
              What, when and how support is to be provided
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Monday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Tuesday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Wednesday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Thursday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Friday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Saturday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Sunday
            </td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
            <td
              className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
              contentEditable
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServiceTable;
