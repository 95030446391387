import React from "react";
import { CompanyDetail } from "../../../types/AuthData";

interface Props {
    appointmentText: string[]
    handleAppointmentChange: (option: string) => void;
    appointmentOptions: string[];
    companyOne: CompanyDetail | null | undefined
}
const EditedAbout = ({ appointmentText, handleAppointmentChange, appointmentOptions, companyOne }: Props) => {
    return (
        <section className="mt-6">
            <article className="mt-5">
                <h3 className="text-black font-bold"><strong>ABOUT THIS AGREEMENT</strong></h3>
                <p className="text-black leading-8">
                    This Service Agreement is made for the purpose of providing an
                    agreement for supports under the NDIS. If you are not able to sign
                    this agreement, a Plan Nominee may also do this. A note will be made
                    at the bottom of this form to explain why someone else has signed the
                    agreement. This agreement explains what you can expect from us and
                    what your responsibilities are when you receive support from us. This
                    agreement can only be made after: we have explained to you the
                    services we offer you have told us verbally that you would like to
                    receive this support from us we have explained our process of signing
                    service agreements with you, including that you can change your mind
                    at any time. If you need help at any time, please speak to a trusted
                    family member, friend or an advocate. Once the agreement has been
                    signed, we will keep the original signed agreement in your file and
                    give a copy to you and those people you nominate. We ask you to let us
                    know in writing (for example in an email) if you do not want a written
                    agreement.
                </p>
            </article>
            <article className="mt-5">
                <h3 className="text-black font-bold"><strong>SCHEDULE OF SUPPORTS</strong></h3>
                <p className="text-black leading-8">
                    {companyOne?.companyName} agrees to provide you (the participant) with the
                    services described in the attached Schedule of Supports. You agree
                    that changes can be made to the Schedule of Supports without changing
                    this Service Agreement (in situations where the required changes to do
                    not reflect an increase in the total cost of your supports from us).
                    This Service Agreement will be updated if there is an increase in the
                    total cost of your required support or your NDIS plan changes. You can
                    confirm your agreement to an updated Schedule of Supports by emailing
                    us at {companyOne?.companyEmail}
                </p>
            </article>
            <article className="mt-5">
                <h3 className="text-black font-bold"><strong>APPOINTMENTS</strong></h3>
                <p className="text-black leading-8">
                    Appointments will be booked for you throughout the duration of this
                    service agreement at a time and day that best suits your preferences.
                </p>
            </article>
            <article className="mt-5">
                <h3 className="text-black font-bold">
                    <strong>CHANGES TO APPOINTMENTS DUE TO A PANDEMIC OR OTHER EMERGENCY</strong>
                </h3>
                <p className="text-black leading-8">
                    The scheduled appointments will continue unless there is a public
                    health order, or you or your worker contract an infectious disease. If
                    the location of the agreed appointment is not available due to an
                    emergency, an alternative location will be identified with you. If
                    appointments are cancelled by either party the cancellation policy
                    will apply. If a worker is unable to attend an appointment the
                    following options will be offered to you. Please tick your preferred
                    option:
                </p>
            </article>
            <fieldset className="mt-5">
                <legend className="sr-only">Appointment Options</legend>
                <div className="space-y-4">
                    {appointmentText.map((item, i) => {
                        return (
                            <div key={i}>
                                <input type="checkbox" id={`checkbox-${i}`}
                                    value={appointmentText}
                                    onChange={() => handleAppointmentChange(item)}
                                    checked={(appointmentOptions || []).includes(item)}
                                    className="mr-2" />
                                <label className="cursor-pointer" htmlFor={`checkbox-${i}`}>
                                    {item}
                                </label>
                            </div>
                        );
                    })}

                </div>
            </fieldset>
            <p className="mt-4">
                I agree that my personal emergency plan will be documented on my support plan, so staff know how to support me in the event of an emergency or other disaster.
            </p>
        </section>
    );
};

export default EditedAbout;
