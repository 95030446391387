import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import {
  fetchSavedInvoice,
  savedInvoiceData,
  savedInvoiceLoading,
} from "../../../store/slices/savedInvoiceSlice";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

interface DataRow {
  description: string;
  fromDate: string;
  toDate: string;
  clientId: number;
  totalDuration: number;
  amount: number;
  totalAgreedDuration: number;
  totalKm: number;
}

function formatDuration(duration: number | string): string {
  const durationInTicks = BigInt(duration);
  const durationInMilliseconds = Number(durationInTicks) / 10000; // Convert ticks to milliseconds

  const durationInSeconds = Math.floor(durationInMilliseconds / 1000); // Convert to seconds
  const totalMinutes = Math.floor(durationInSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  let result = "";

  if (totalHours > 0) {
    result += `${totalHours} Hrs`;
  }

  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} Min`;
  }

  return result.trim(); // Remove trailing space
}

const SavedInvoice = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={<Tooltip>{"Click to view Invoice"}</Tooltip>}
        >
          <Link
            to={`/app/reports/view-saved-invoice/${row.fromDate}/${row.toDate}/${row.clientId}`}
            className="truncate font-bold"
            style={{ overflow: "hidden", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title={""}
          >
            {row.description}
          </Link>
        </Whisper>
      ),
      // cell: (row) => (
      //     <Link style={{ overflow: "hidden" }} to={`/app/reports/get-all-invoice/${row.fromDate}/${row.toDate}/${row.clientId}`} className="font-bold truncate">
      //         {row.description}
      //     </Link>
      // ),
    },
    {
      name: "Total Duration",
      selector: (row) => formatDuration(row.totalDuration),
      sortable: true,
    },

    {
      name: "Total Amount ($)",
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: "Total Agreed",
      selector: (row) => row.totalAgreedDuration,
      sortable: true,
    },

    {
      name: "Total Km",
      selector: (row) => row.totalKm,
      sortable: true,
    },
  ];

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [saveIds, setSaveIds] = useState<DataRow | null>(null);
  const [loading1, setLoading1] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchSavedInvoice(companyId));
    }
  }, [dispatch, companyId]);
  const savedInvoice = useSelector(savedInvoiceData);
  const loading = useSelector(savedInvoiceLoading);
  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Description: </span> {data.description}
        </div>
        <div>
          <button
            className="btn text-red-500 font-bold"
            title="Delete"
            onClick={() => delInvoice(data)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const delInvoice = (id: DataRow) => {
    setSaveIds(id);
    setDeleteModal(true);
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = savedInvoice?.filter(
    (item) =>
      item?.description?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.fromDate?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Invoice/delete_invoices?userId=${user?.uid}&fromDate=${saveIds?.fromDate}&toDate=${saveIds?.toDate}&clientId=${saveIds?.clientId}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      companyId && dispatch(fetchSavedInvoice(companyId));
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setDeleteModal(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO
        title="Service Providers"
        description="Service Provider"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Saved Invoice
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Description"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        savedInvoice,
                        columns as unknown as Column<DataRow>[],
                        "SavedInvoice.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        savedInvoice,
                        columns as unknown as Column<DataRow>[],
                        "SavedInvoice"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: savedInvoice,
                        filename: "SavedInvoice.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={savedInvoice?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete Saved Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this Invoice?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDelete}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SavedInvoice;
