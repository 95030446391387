import React, { ChangeEvent, useEffect, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaEye, FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import Spinner from '../../../Components/Spinner';
import { Tooltip, Whisper } from 'rsuite';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';
import dayjs from 'dayjs';
import { fetchShiftReport, shiftReportData, ShiftReport, shiftReportLoading } from '../../../store/slices/shiftReportSlice';
import { useNavigate } from 'react-router-dom';

const StaffShiftReport = () => {

    const columns: TableColumn<ShiftReport>[] = [
        {
            name: 'Staff',
            selector: row => row.shiftRoster.staff.fullName,
            sortable: true,
            cell: (row) => <span className="font-bold truncate" style={{ overflow: "hidden", cursor: "pointer" }}
            >{row.shiftRoster.staff.fullName}</span>

        },
        {
            name: 'Client',
            selector: row => row.shiftRoster.profile.fullName,
            sortable: true,
            cell: (row) => <span className="font-bold truncate" style={{ overflow: "hidden", cursor: "pointer" }}
            >{row.shiftRoster.profile.fullName}</span>

        },
        {

            name: 'Start Time',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('h:mm A'),
            sortable: true,

        },
        {
            name: 'End Date',
            selector: row => dayjs(row.shiftRoster.dateTo).format('h:mm A'),
            sortable: true,
        },
        {

            name: 'Date',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('ddd, MMMM DD YYYY'),
            sortable: true

        },
        // {
        //     name: 'Duration',
        //     selector: row => formatDuration(row.duration),
        //     sortable: true,

        // }

        {
            name: "Actions",
            cell: (row) => (
                <span className="flex gap-4">

                    <div>
                        <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                View Shift Report Form
                            </Tooltip>
                        }>
                            <button
                                onClick={() => FillReport(row.shiftReportId, row.shiftRosterId)}
                                className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                                <FaEye className="text-base cursor-pointer" />
                            </button>
                        </Whisper>
                    </div>

                </span>
            ),
        }

    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchShiftReport(companyId));
        }
    }, [dispatch, companyId]);
    const staffShiftReports = useSelector(shiftReportData);

    const navigate = useNavigate()

    const FillReport = (shiftId: number, id: number) => {
        navigate(`/app/reports/view-shift-report/${shiftId}/${id}`)
    }

    const loading = useSelector(shiftReportLoading);
    interface Props extends ExpanderComponentProps<ShiftReport> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Staff: </span>
                    <span> {data.shiftRoster.staff.fullName}</span>
                </span>
                <span>
                    <span className='font-bold'>Client: </span>
                    <span> {data.shiftRoster.profile.fullName}</span>
                </span>

                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = staffShiftReports?.filter((item) =>
        item?.shiftRoster?.staff?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.shiftRoster?.profile?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <SEO
                title='Shift Report'
                description='Shift Report'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {staffShiftReports.length <= 0 && loading && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Shift Report</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 
                                text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2
                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Staff or Client Name" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">


                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(staffShiftReports, columns as unknown as Column<ShiftReport>[], "ShiftReport.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(staffShiftReports, columns as unknown as Column<ShiftReport>[], "ShiftReport")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: staffShiftReports, filename: 'ShiftReport.csv' })}

                                    />
                                </div>
                            </ExportDropdown>

                        </div>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={staffShiftReports?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>


        </>
    )
}

export default StaffShiftReport