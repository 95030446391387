import React, { FormEvent, useState } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import CustomSelect from '../../../../Components/Input/CustomSelect';
import Spinner from '../../../../Components/Spinner';
import { ClientDataRow } from '../../../../types/AuthData';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
interface Props {
    personalInfoModal: boolean
    setPersonalInfoModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: ClientDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
    address: string
    setAddress: React.Dispatch<React.SetStateAction<string>>
}
interface Prediction {
    place_id: number;
    description: string;
}
const ClientEditComp = ({ personalInfoModal, setPersonalInfoModal, address, setAddress,
    editedProfile, handleInputChange, submitData, loading1 }: Props) => {

    const [showPredictions, setShowPredictions] = useState<boolean>(false);

    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    })

    const handleInputEdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
        if (event.target.value !== "") {
            setShowPredictions(true);
            getPlacePredictions({ input: event.target.value });
        } else {
            setShowPredictions(false);
        }
    };

    const handleSelect = (description: string) => {
        setAddress(description);
        setShowPredictions(false);
    };

    return (
        <>
            <Modal
                open={personalInfoModal}
                onClose={() => setPersonalInfoModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Personal Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                                <CustomInput
                                    label="Last Name"
                                    type="text"
                                    value={editedProfile.surName}
                                    readOnly
                                />
                                <CustomInput
                                    label="First Name"
                                    type="text"
                                    value={editedProfile.firstName}
                                    readOnly
                                />
                                <CustomInput
                                    label="Middle Name"
                                    type="text"
                                    name='middleName'
                                    value={editedProfile.middleName || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Phone Number"
                                    type="text"
                                    name='phoneNumber'
                                    value={editedProfile.phoneNumber || ""}
                                    required
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Date Of Birth"
                                    type="date"
                                    name='dateOfBirth'
                                    value={editedProfile.dateOfBirth || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Email"
                                    type="email"
                                    name='email'
                                    value={editedProfile.email || ""}
                                    readOnly
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="Gender"
                                    name="gender"
                                    options={[
                                        { value: '', label: 'Select Gender' },
                                        { value: 'Male', label: 'Male' },
                                        { value: 'Female', label: 'Female' },
                                        { value: 'Transgender', label: 'Transgender' },
                                        { value: 'Non-binary/non-conforming', label: 'Non-binary/non-conforming' },
                                        { value: 'Prefer not to respond', label: 'Prefer not to respond' }
                                    ]}
                                    value={editedProfile.gender || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Country"
                                    type="text"
                                    name='country'
                                    value={editedProfile.country || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="State"
                                    type="text"
                                    name='state'
                                    value={editedProfile.state || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="City"
                                    type="text"
                                    name='city'
                                    value={editedProfile.city || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Agreement Start Date"
                                    type="date"
                                    name='agreementStartDate'
                                    value={editedProfile.agreementStartDate || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Agreement End Date"
                                    type="date"
                                    name='agreementEndDate'
                                    value={editedProfile.agreementEndDate || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Suburb"
                                    type="text"
                                    name='suburb'
                                    value={editedProfile.suburb || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Ndis No"
                                    type="text"
                                    name='ndisNo'
                                    value={editedProfile.ndisNo || ""}
                                    onChange={handleInputChange}
                                />
                                {/* <div>
                                    <CustomInput
                                        label="Address"
                                        type="text"
                                        name='address'
                                        className='w-full'
                                        value={editedProfile.address || ""}
                                        onChange={handleInputChange}
                                    />
                                </div> */}
                                <div className="relative">
                                    <CustomInput
                                        label="Address"
                                        type="text"
                                        name="address"
                                        className="w-full"
                                        value={address}
                                        onChange={handleInputEdChange}
                                    />
                                    {isPlacePredictionsLoading &&
                                        <div className="absolute z-10 top-[4.5rem] bg-white">
                                            <Spinner />
                                        </div>
                                    }
                                    {showPredictions &&
                                        // (
                                        //     <div className="predictions-container" style={{ position: 'absolute', zIndex: 1000, backgroundColor: '#fff', width: '100%' }}>
                                        //         {!isPlacePredictionsLoading && placePredictions.map((item: Prediction) => (
                                        //             <div
                                        //                 key={item.place_id}
                                        //                 onClick={() => handleSelect(item.description)}
                                        //                 style={{ cursor: 'pointer', padding: '10px', borderTop: '1px solid #ccc' }}
                                        //             >
                                        //                 {item.description}
                                        //             </div>
                                        //         ))}
                                        //     </div>
                                        // )
                                        (
                                            <div
                                                className=" flex flex-col overflow-y-auto absolute z-10 top-[4.5rem] shadow-md bg-white p-4 rounded border"
                                            >
                                                <ul>
                                                    {placePredictions.map((item: Prediction) => (
                                                        <li key={item.place_id} onClick={() => handleSelect(item.description)}
                                                            className="hover:bg-gray-100 py-2 px-1"
                                                        >
                                                            {item.description}
                                                        </li>
                                                    ))}

                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClientEditComp