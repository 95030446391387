import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { thisMonth, thisYear } from "../../services/reusableValue";
import { Link } from "react-router-dom";

const Calendar: React.FC = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");

  const [currentMonth, setCurrentMonth] = useState<number>(thisMonth);
  const [currentYear, setCurrentYear] = useState<number>(thisYear);
  const [currentDay, setCurrentDay] = useState<string>(
    dayjs().tz().format("YYYY-MM-DD")
  );

  // const [shift] = useState<Shift[]>(shiftRes || [])

  // const currentDay = dayjs().format('YYYY-MM-DD')

  // const activitiesByDay = shift.filter((activity) =>
  //     dayjs(activity.dateFrom).format('YYYY-MM-DD') === currentDay
  // );
  // const activitiesByDay = shift
  //   .filter(
  //     (activity) => dayjs(activity.dateFrom).format("YYYY-MM-DD") === currentDay
  //   )
  //   .slice(0, 2);

  const today: string = dayjs().tz().format("YYYY-MM-DD");

  // console.log(activitiesByDay);

  const handleNext = () => {
    // Calculate the next month and year
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;

    // Update state to display the next month
    setCurrentMonth(nextMonth);
    setCurrentYear(nextYear);
  };

  const handleDayClick = (day: string) => {
    // Handle click event for each day
    setCurrentDay(day);
    // alert(day)
  };

  const handleNextDate = () => {
    // Calculate the next month and year
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;

    // Update state to display the next month
    setCurrentMonth(nextMonth);
    setCurrentYear(nextYear);
  };

  const handlePrevious = () => {
    // Calculate the previous month and year
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Update state to display the previous month
    setCurrentMonth(previousMonth);
    setCurrentYear(previousYear);
  };

  // Get the first day of the month
  const firstDayOfMonth = dayjs().year(currentYear).month(currentMonth).date(1);

  // Get the weekday index of the first day of the month (0 for Sunday, 1 for Monday, etc.)
  const startDayOfWeek = firstDayOfMonth.day();

  // Get the number of days in the month
  const daysInMonth = firstDayOfMonth.daysInMonth();

  // const daysArray: string[] = [];

  // // Push empty strings for days before the first day of the month
  // for (let i = 0; i < startDayOfWeek; i++) {
  //     daysArray.push('');
  // }

  // // Populate the days of the month
  // for (let i = 1; i <= daysInMonth; i++) {
  //     daysArray.push(dayjs(firstDayOfMonth).date(i).format('YYYY-MM-DD'));
  // }

  const daysArray: string[] = [];

  // Fill up empty spaces with faded dates for the previous month
  const previousMonthDays = firstDayOfMonth.subtract(startDayOfWeek, "day");
  for (let i = 0; i < startDayOfWeek; i++) {
    daysArray.push(previousMonthDays.add(i, "day").format("YYYY-MM-DD"));
  }

  // Populate the days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    daysArray.push(dayjs(firstDayOfMonth).date(i).format("YYYY-MM-DD"));
  }

  // Fill up remaining days with faded dates for the next month
  const remainingDays =
    daysArray.length > 35 ? 42 - daysArray.length : 35 - daysArray.length;
  for (let i = 0; i < remainingDays; i++) {
    // Changed starting index to 0
    daysArray.push(
      firstDayOfMonth.add(daysInMonth + i, "day").format("YYYY-MM-DD")
    );
  }

  // const hasShift = (day: string) => {
  //   return shift.some(
  //     (shift) =>
  //       dayjs(shift.dateFrom).format("YYYY-MM-DD") ===
  //       dayjs(day).format("YYYY-MM-DD")
  //   );
  // };

  return (
    <div className="calendar bg-white rounded-lg shadow-sm border p-4">
      <div className="mx-auto bg-white space-y-2 ">
        <div className="flex justify-between items-center">
          <div className="flex items-center justify-between gap-2 w-full">
            <button
              className="p-2  rounded-md bg-gray-200 transition duration-300 transform active:scale-95 ease-in-out"
              onClick={handlePrevious}
            >
              <MdChevronLeft className="text-gray-500" />
            </button>
            <span className="font-bold text-sm md:text-lg text-primary">
              {dayjs().month(currentMonth).format("MMMM")} {currentYear}
            </span>
            <button
              className="p-2 rounded-md bg-gray-200 transition duration-300 transform active:scale-95 ease-in-out"
              onClick={handleNextDate}
            >
              <MdChevronRight className="text-gray-500" />
            </button>
          </div>
        </div>

        <div className="flex justify-between text-black font-bold bg-gray-100 text-xs md:p-3 p-2">
          <div className="text-gray-500 ">SUN</div>
          <div className="text-gray-500 ">MON</div>
          <div className="text-gray-500 ">TUE</div>
          <div className="text-gray-500 ">WED</div>
          <div className="text-gray-500 ">THU</div>
          <div className="text-gray-500 ">FRI</div>
          <div className="text-gray-500 ">SAT</div>
        </div>

        <div className="grid grid-cols-7 gap-4 mx-auto items-center justify-evenly p-1 ">
          {daysArray.map((day, index) => (
            <span
              key={index}
              className={`calendar-day  border  rounded-ss-lg rounded-ee-lg  flex font-bold items-center cursor-pointer justify-center px-1 h-9 text-xs   transform transition duration-500 
                        hover:scale-110 } ${
                          dayjs(day).month() !== currentMonth
                            ? "text-gray-300"
                            : ""
                        }  ${
                day === currentDay
                  ? "border-primary border bg-primary/20 text-primary"
                  : ""
              }
                ${
                  day === today
                    ? "border bg-secondary text-white py-1 px-2 rounded"
                    : ""
                }
                
                `}
              onClick={() => handleDayClick(day)}
            >
              <span className="flex justify-center items-center flex-col">
                {day ? dayjs(day).format("D") : ""}
              </span>
            </span>
          ))}
        </div>
      </div>
      {/* Next button */}
      <button
        className="hidden mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        onClick={handleNext}
      >
        Next
      </button>

      <div className="space-y-1">
        {/* <div>
          <span className="font-bold text-primary">Recent Shifts</span>
        </div> */}
        {/* {shift.length <= 0 && loading && (
          <div className="flex justify-center items-center p-4 mx-auto">
            <Spinner />
          </div>
        )} */}

        {/* {!loading && activitiesByDay?.length > 0 ? (
          <div>
            <table className="table-fixed min-w-full">
              <tbody>
                {activitiesByDay.length > 0 &&
                  activitiesByDay.map((activity, index) => (
                    <tr key={index} className="text-xs">
                      <td className="font-bold capitalize">
                        {activity?.staff?.fullName}
                      </td>
                      <td>{activity?.profile?.fullName}</td>
                      <td className="hidden md:block">
                        {dayjs(activity.dateFrom).format("hh:mm A")} -{" "}
                        {dayjs(activity.dateTo).format("hh:mm A")}
                      </td>
                      <td>
                        {activity.attendance ? (
                          <span className="block bg-primary w-1 h-1 mt-1 rounded-full" />
                        ) : (
                          <span className="block bg-gray-200 w-1 h-1 mt-1 rounded-full" />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex justify-center items-center p-4 mx-auto">
            <p>No Shift</p>
          </div>
        )} */}
        <div className="flex justify-center text-xs text-blue-700 py-2">
          <Link to={"/app/shift/roster"}>View all Shift Roster</Link>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
