import React, { FormEvent, useEffect, useRef, useState } from "react";
import Hero from "./__component/Hero";
import SummarySupport from "./__component/SummarySupport";
import AlertTable from "./__component/AlertTable";
import Preference from "./__component/Preference";
import PrintComponent from "../PrintTab";
import { Client } from "../../store/slices/clientSlice";
import { fetchClientInfo, fetchCompany, fetchParticipantProfiledoc } from "../../services/fetchServices";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../Components/label/BreadCrumb";
import Spinner from "../../Components/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Date from "./__component/Date";
import { ParticipantForm } from "../../types/DataType";
import EditParticipantProfile from "./EditParticiantProfile";

const ParticipantProfile = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const [checker, setChecker] = useState<ParticipantForm | null>(null)
  const [participantForm, setParticipantForm] = useState<ParticipantForm>({
    participantProfileId: 0,
    profileId: 0,
    document_Name: "",
    dietary_Needs: "",
    mobility_Needs: "",
    implementationDate: "",
    expirationDate: "",
    communication_Preferences: "",
    medication: "",
    important_Things: "",
    preferred_Activities: "",
    dislikes: "",
    companyId: 0
  });

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchDoc()
  }, [uid])

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };
  const componentRef = useRef<HTMLDivElement>(null);

  const dietary_Needs = useRef<HTMLTableCellElement>(null);
  const mobility_Needs = useRef<HTMLTableCellElement>(null);
  const communication_Preferences = useRef<HTMLTableCellElement>(null);
  const medication = useRef<HTMLTableCellElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const [alertValues, setAlertValues] = useState<string[]>([""]);
  const [prefValues, setPrefValues] = useState(Array(5).fill(""));
  const [disLikeValues, setDisLikeValues] = useState(Array(5).fill(""));

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...alertValues];
    newValues[index] = value;
    setAlertValues(newValues);
  };

  const handleInputChang = (index: number, value: string) => {
    const newValues = [...prefValues];
    newValues[index] = value;
    setPrefValues(newValues);
  };

  const handleInputChan = (index: number, value: string) => {
    const newValues = [...disLikeValues];
    newValues[index] = value;
    setDisLikeValues(newValues);
  };

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setParticipantForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddRow = () => {
    setAlertValues([...alertValues, ""]);
  };

  const [activities, setActivities] = useState<string[]>([]);
  const [dislike, setDislike] = useState<string[]>([]);
  const [important, setImportant] = useState<string[]>([]);

  const handleInputEditChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newActivities = [...activities];
    newActivities[index] = event.target.value;
    setActivities(newActivities);
  };

  const handleInputDislikeChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newActivities = [...dislike];
    newActivities[index] = event.target.value;
    setDislike(newActivities);
  };

  const handleInputImportantChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newActivities = [...important];
    newActivities[index] = event.target.value;
    setImportant(newActivities);
  };

  const handleAddRowEdit = () => {
    setImportant([...important, '']);
  };

  const fetchDoc = async () => {
    const data = await fetchParticipantProfiledoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-101 P02 Page Participant profile"
    );
    setParticipantForm({ ...data });
    setChecker(data)
    setActivities(data?.preferred_Activities.split(',').map((activity: string) => activity.trim()))
    setDislike(data?.dislikes.split(',').map((activity: string) => activity.trim()))
    setImportant(data?.important_Things.split(',').map((activity: string) => activity.trim()))
  };


  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formattedValues = important.join(",")
    const formattedActivities = activities.join(",")
    const formattedPreferred = dislike.join(",")
    setLoading(true);
    const info = {
      participantProfileId: participantForm.participantProfileId,
      profileId: uid,
      document_Name: "PCD-101 P02 Page Participant profile",
      dietary_Needs: participantForm.dietary_Needs,
      mobility_Needs: participantForm.mobility_Needs,
      implementationDate: participantForm.implementationDate,
      expirationDate: participantForm.expirationDate,
      communication_Preferences: participantForm.communication_Preferences,
      medication: participantForm.medication,
      important_Things: formattedValues,
      preferred_Activities: formattedActivities,
      dislikes: formattedPreferred,
      companyId: user?.companyId
    }
    try {
      const { data } = await axiosInstance.post(
        `/ParticipantProfiles/edit/${participantForm.participantProfileId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formattedValues = alertValues.join(",")
    const formattedPreferred = prefValues.join(",")
    const formattedDislike = disLikeValues.join(",")
    const expirationDateValue = ExpirationDate.current?.value;
    setLoading(true);
    const info = {

      profileId: uid,
      document_Name: "PCD-101 P02 Page Participant profile",
      dietary_Needs: dietary_Needs?.current?.innerText,
      mobility_Needs: mobility_Needs?.current?.innerText,
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      communication_Preferences: communication_Preferences?.current?.innerText,
      medication: medication?.current?.innerText,
      important_Things: formattedValues,
      preferred_Activities: formattedPreferred,
      dislikes: formattedDislike,
      companyId: user?.companyId
    }
    try {
      const { data } = await axiosInstance.post(
        `/ParticipantProfiles/fill_profile?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditParticipantProfile
          companyOne={companyOne}
          clientDetail={clientDetail}
          participantForm={participantForm}
          handleInputDataChange={handleInputDataChange}
          handleAddRow={handleAddRowEdit}
          handleEditSubmit={handleEditSubmit}
          loading={loading}
          dislike={dislike}
          activities={activities}
          important={important}
          handleInputEditChange={handleInputEditChange}
          handleInputDislikeChange={handleInputDislikeChange}
          handleInputImportantChange={handleInputImportantChange}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print this Document"
            documentTitle="Participant Profile"
          />
          <div ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero companyOne={companyOne} clientDetail={clientDetail} />
            <SummarySupport
              communication_Preferences={communication_Preferences}
              dietary_Needs={dietary_Needs}
              medication={medication}
              mobility_Needs={mobility_Needs}
            />
            <AlertTable
              alertValues={alertValues}
              handleAddRow={handleAddRow}
              handleInputChange={handleInputChange}
            />
            <Preference
              handleInputChang={handleInputChang}
              prefValues={prefValues}
              handleInputChan={handleInputChan}
              disLikeValues={disLikeValues}
            />
            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : " Submit"}
            </button>
          </div>
        </form>
      )}

    </div>
  );
};

export default ParticipantProfile;
