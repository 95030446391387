import React, { useEffect, useState } from "react";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import {
  StaffAvailability,
  fetchStaffAvailability,
  staffAvailabilityData,
  staffAvailabilityLoading,
} from "../../../store/slices/staffAvailabilitySlice";
import { StaffProfileTypes } from "../../../types/AuthData";
import { useSelector } from "react-redux";
import axiosInstance from "../../../store/axiosInstance";
import DayAvailabilityForm from "./components/DayAvailabilityForm";
import Spinner from "../../../Components/Spinner";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";

export interface TimeSlot {
  id: string;
  startTime: string;
  endTime: string;
  loading?: boolean; // Optional property to track loading state
}
export interface DaySlots {
  [day: string]: TimeSlot[];
}

const AvailableHoursForm: React.FC = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;

  const [availableHours, setAvailableHours] = useState<DaySlots>({});
  const [overlapErrors, setOverlapErrors] = useState<{
    [key: string]: boolean[];
  }>({});
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dispatch: AppDispatch = useAppDispatch();
  const staffSchedule = useSelector(staffAvailabilityData);
  const loadingData = useSelector(staffAvailabilityLoading);

  useEffect(() => {
    dispatch(fetchStaffAvailability(staffProfile.staffId));
    organizeHours();
  }, [dispatch, staffSchedule]);

  const organizeHours = () => {
    const hoursByDay: DaySlots = {};
    staffSchedule.forEach((slot: StaffAvailability) => {
      if (!hoursByDay[slot.days]) {
        hoursByDay[slot.days] = [];
      }
      hoursByDay[slot.days].push({
        id: slot.staffAvailibilityId.toString(),
        startTime: slot.fromTimeOfDay,
        endTime: slot.toTimeOfDay,
        loading: false, // Initialize with no loading
      });
    });
    setAvailableHours(hoursByDay);
  };

  const handleSubmit = async (
    day: string,
    newStartTime: string,
    newEndTime: string
  ) => {
    try {
      // setLoading(true)
      const { data } = await axiosInstance.post(
        `/StaffAvailibilities/add_staff_availability?userId=${user?.uid}`,

        {
          staffId: staffProfile.staffId,
          days: day,
          fromTimeOfDay: newStartTime,
          toTimeOfDay: newEndTime,
          companyID: user?.companyId,
        }
      );

      if (data.status === "Success") {
        toast.success(data.message);
        // setLoading(false);
        dispatch(fetchStaffAvailability(staffProfile.staffId));
      } else {
        toast.error(data.message);
        // setLoading(false)
        dispatch(fetchStaffAvailability(staffProfile.staffId));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
      }
      toast.error("Unable to add time schedule! Please try again later");
      dispatch(fetchStaffAvailability(staffProfile.staffId));
    }
  };
  const handleEdit = async (
    day: string,
    id: string,
    field: string,
    value: string
  ) => {
    try {
      // Fetch the existing time slot to get the current values
      const existingTimeSlot = availableHours[day].find(
        (slot) => slot.id === id
      );

      const payload = {
        staffAvailibilityId: id,
        staffId: staffProfile.staffId,
        days: day,
        fromTimeOfDay:
          field === "startTime"
            ? value
            : existingTimeSlot && existingTimeSlot.startTime,
        toTimeOfDay:
          field === "endTime"
            ? value
            : existingTimeSlot && existingTimeSlot.endTime,
        companyID: user?.companyId,
      };

      const { data } = await axiosInstance.post(
        `/StaffAvailibilities/edit/${id}?userId=${user?.uid}`,
        payload
      );
      if (data.status === "Success") {
        toast.success(data.message);
        // setLoading(false);
        dispatch(fetchStaffAvailability(staffProfile.staffId));
      } else {
        toast.error(data.message);
        // setLoading(false)
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);

        toast.error(error.response?.data?.message);
      }
      toast.error("Unable to edit time schedule! Please try again later");
    }
  };

  const handleAddTimeSlot = (day: string): void => {
    const lastSlot = availableHours[day]?.[availableHours[day]?.length - 1];
    const newId = `temp-${new Date().getTime()}`;

    // Calculate the start and end times for the new time slot
    const newStartTime = lastSlot ? addHour(lastSlot.endTime) : "07:00";
    const newEndTime = addHour(newStartTime);
    handleSubmit(day, newStartTime, newEndTime);

    // setAvailableHours((prevHours) => ({
    //     ...prevHours,
    //     [day]: [
    //         ...(prevHours[day] || []),
    //         { startTime: newStartTime, endTime: newEndTime, id: "" },
    //     ],
    // }));
    setAvailableHours((prev) => ({
      ...prev,
      [day]: [
        ...(prev[day] || []),
        {
          id: newId,
          startTime: newStartTime,
          endTime: newEndTime,
          loading: true,
        },
      ],
    }));
  };

  const handleDeleteTimeSlot = async (
    day: string,
    index: number,
    id: string
  ) => {
    setAvailableHours((prevHours) => ({
      ...prevHours,
      [day]: prevHours[day].filter((_, i) => i !== index),
    }));

    try {
      const { data } = await axiosInstance.post(
        `/StaffAvailibilities/delete/${id}`
      );
      if (data.status === "Success") {
        toast.success(data.message);
        dispatch(fetchStaffAvailability(staffProfile.staffId));
      } else {
        toast.error(data.message);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);

        toast.error(error.response?.data?.message);
      }
      console.log(error);
      toast.error("Unable to delete time schedule! Please try again later");
    }
  };

  const addHour = (time: string): string => {
    const [hour, minute] = time.split(":").map((str) => parseInt(str));
    const nextHour = hour === 23 ? 0 : hour + 1;
    return `${nextHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const handleChangeTime = (
    day: string,
    index: number,
    field: keyof TimeSlot,
    id: string,
    value: string
  ): void => {
    // Update the time slot in the local state
    setAvailableHours((prevHours) => ({
      ...prevHours,
      [day]: prevHours[day].map((slot, i) =>
        i === index ? { ...slot, [field]: value } : slot
      ),
    }));

    // Check for overlapping time slots
    const newStartTime =
      field === "startTime" ? value : availableHours[day][index].startTime;
    const newEndTime =
      field === "endTime" ? value : availableHours[day][index].endTime;

    // Check if endTime is greater than startTime
    if (field === "endTime" && newStartTime >= newEndTime) {
      // Reset the time slot in the local state
      setAvailableHours((prevHours) => ({
        ...prevHours,
        [day]: prevHours[day].map((slot, i) =>
          i === index
            ? { ...slot, [field]: availableHours[day][index][field] }
            : slot
        ),
      }));

      // Show error message
      toast.error("End time must be greater than start time!");
      return;
    }

    // Check for overlapping time slots
    const overlapping = availableHours[day].map((slot, i) => {
      if (i === index) return false; // Skip checking against itself
      return (
        (newStartTime >= slot.startTime && newStartTime < slot.endTime) ||
        (newEndTime > slot.startTime && newEndTime <= slot.endTime) ||
        (newStartTime <= slot.startTime && newEndTime >= slot.endTime)
      );
    });

    // Update overlapErrors state
    setOverlapErrors((prevErrors) => ({
      ...prevErrors,
      [day]: overlapping,
    }));

    // If any overlap, return without editing
    if (overlapping.includes(true)) {
      // Show error message
      toast.error("Overlapping time slots not allowed!");
      return;
    }

    // If no overlaps and endTime is greater than startTime, proceed with the edit
    handleEdit(day, id, field, value);
  };

  return (
    <>
      <SEO
        title={` Staff Availability`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div className="flex justify-between items-center flex-wrap my-4">
        <h2 className="font-bold text-2xl text-black w-auto">Availability</h2>
      </div>
      <div className="bg-white py-4 px-2 ">
        <div className="max-w-screen-lg mx-auto  space-y-4 p-2  overflow-hidden">
          {staffSchedule.length <= 0 && loadingData && (
            <Spinner color="#071952" />
          )}
          <div className="space-y-1  items-center  rounded-md">
            <div className="bg-gray-200 text-center p-2 flex justify-between items-center text-base lg:text-lg font-semibold rounded-t-md ">
              <span className="uppercase text-black">
                Add your availability & Schedule
              </span>{" "}
            </div>
          </div>
          <div className="py-4 rounded-lg divide-y divide-gray-100   relative">
            <span className="bg-gray-200 rounded absolute top-0 right-0 p-2  text-xs font-bold">
              Add Time Slot using the plus button(+)
            </span>
            <div className="py-4 w-full lg:grid grid-cols-1 gap-4  lg:grid-cols-3 lg:gap-8 items-start hidden ">
              <div className="flex gap-4 items-center justify-between lg:justify-start">
                <span>-</span>

                <h4 className="text-base font-bold text-primary uppercase">
                  Days
                </h4>
              </div>
              <div className="lg:col-span-2 flex items-start">
                <h4 className="text-base font-bold text-primary uppercase">
                  Time Slots
                </h4>
              </div>
            </div>
            {daysOfWeek.map((day, index) => (
              <DayAvailabilityForm
                key={index}
                availableHours={availableHours}
                day={day}
                handleAddTimeSlot={handleAddTimeSlot}
                handleChangeTime={handleChangeTime}
                handleDeleteTimeSlot={handleDeleteTimeSlot}
                index={index}
                overlapErrors={overlapErrors}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableHoursForm;
