import React, { useEffect, useState } from "react";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { fetchStaffDocument } from "../../../../services/fetchServices";
import Spinner from "../../../../Components/Spinner";
import dayjs from "dayjs";
import { FaEye } from "react-icons/fa";
import { Modal, Tooltip, Whisper } from "rsuite";
import { documentNames } from "./data";
import { Document } from "../../../../store/slices/documentSlice";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import { MdUploadFile } from "react-icons/md";
import CustomInput from "../../../../Components/Input/CustomInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";

interface Props {
  uid: string;
  staffName: string;
}

interface DocumentData {
  id: string; // Unique identifier for the document
  docName: string; // Display name for the document
  docUrl?: string; // URL to the document if available
  docDetails?: string; // Additional details or status
  implementationDate?: string; // Optional expiration date
  expirationDate?: string; // Optional expiration date
  documentUrl?: string; // URL of the document for internal use
  lastModified?: string;
}

interface DataRow extends DocumentData {
  status: string; // Status might be a computed value or derived from docDetails
  user?: string; // User associated with the document, if any
  date?: string; // Date related to the document
}

const FeaturedDocument = ({ uid, staffName }: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const user = useSelector((state: RootState) => state?.auth?.user);
  const companyId = user?.companyId;
  const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");
  const [filteredData, setFilteredData] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const result: Document[] = await fetchStaffDocument(Number(uid));

      const mergedData: DataRow[] = documentNames.map((docName, index) => {
        const fetchedDoc = result.find((doc) => doc.documentName === docName);
        return {
          id: index.toString(),
          docName,
          docUrl: fetchedDoc?.documentUrl,
          status: fetchedDoc?.status as string,
          implementationDate: fetchedDoc?.implementationDate,
          expirationDate: fetchedDoc?.expirationDate,
          documentUrl: fetchedDoc?.documentUrl,
          lastModified: fetchedDoc?.dateModified,
        };
      });

      setFilteredData(mergedData);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDocuments();
  }, [uid]);

  const handleView = (documentUrl: string | undefined): void => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    } else {
      toast.error("No document found");
    }
  };

  const [docuName, setDocuName] = useState("");
  const [uploadModal, setUploadModal] = useState(false);

  const handleUpload = (docName: string): void => {
    setDocuName(docName);
    setUploadModal(true);
    // Implement the logic to handle document upload
  };
  const [docFile, setDocFile] = useState<File | null>(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setDocFile(file);
    }
  };

  const submitData = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!docFile) {
      toast.error("No File Selected");
      return;
    }
    setLoadingSubmit(true);
    const formData = new FormData();
    formData.append("CompanyId", companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
    formData.append("DocumentName", docuName);
    formData.append("ExpirationDate", expirationDate);
    formData.append("User", staffName);
    formData.append("Status", "Pending");
    formData.append("UserRole", "Staff");
    formData.append("UserId", uid);
    docFile && formData.append("DocumentFile", docFile);

    try {
      const { data } = await axiosInstance.post(
        `/Documents/add_document?userId=${user?.uid}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data?.message);
        setUploadModal(false);
        setLoadingSubmit(false);
        fetchDocuments();
      } else {
        toast.error(data.message);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Document Name",
      selector: (row) => row.docName,
      sortable: true,
    },
    {
      name: "Last Modified",
      selector: (row) =>
        row.lastModified ? dayjs(row.lastModified).format("DD-MM-YYYY") : "N/A",
    },
    {
      name: "Expiration Date",
      selector: (row) =>
        row.expirationDate
          ? dayjs(row.expirationDate).format("DD-MM-YYYY")
          : "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const isExpired =
          row.expirationDate &&
          dayjs(row.expirationDate).isBefore(nowInAustraliaTime);
        let status;

        if (!row.expirationDate && !row.status) {
          status = "N/A";
        } else if (isExpired) {
          status = "Expired";
        } else {
          status = row.status || "N/A";
        }

        const statusClasses = `px-8 py-2 rounded font-bold ${
          isExpired
            ? "bg-red-400 text-white"
            : row.status === "Pending"
            ? "bg-secondary text-white"
            : row.status === "Accepted"
            ? "bg-primary text-white"
            : row.status === "Rejected"
            ? "bg-red-500 text-white"
            : "bg-slate-400"
        }`;

        return (
          <span
            className={statusClasses}
            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
          >
            {status}
          </span>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <span className="flex gap-4">
          <div>
            <Whisper
              placement="auto"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>View Document</Tooltip>}
            >
              <button
                onClick={() => handleView(row.docUrl)}
                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
              >
                <FaEye className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>
          {(!row.expirationDate || row.status === "N/A") && (
            <div>
              <Whisper
                placement="auto"
                controlId="control-id-hover"
                trigger="hover"
                speaker={<Tooltip>Upload Document</Tooltip>}
              >
                <button
                  onClick={() => handleUpload(row.docName)}
                  className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                >
                  <MdUploadFile className="text-base cursor-pointer" />
                </button>
              </Whisper>
            </div>
          )}
        </span>
      ),
    },
  ];

  const ExpandableRowComponent: React.FC<ExpanderComponentProps<DataRow>> = ({
    data,
  }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Document Name: </span> {data.docName}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mt-5">
        {filteredData?.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex justify-between items-center flex-wrap my-4">
          <h2 className="font-bold text-2xl text-black w-auto">
            Documents<span className="text-red-500">*</span>
          </h2>
          <button
            onClick={() => {
              setUploadModal(true);
              setDocuName("");
            }}
            className="px-4 py-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   "
          >
            Upload other documents
          </button>
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          pagination={false}
          expandableRowsComponent={ExpandableRowComponent}
        />
      </div>

      <Modal
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={submitData}>
              <div className="p-2 grid grid-cols-1 gap-4">
                <CustomInput
                  label="Document Name"
                  type="text"
                  value={docuName}
                  onChange={(e) => setDocuName(e.target.value)}
                  autoComplete="off"
                  required
                  minLength={2}
                />

                <CustomInput
                  label="Expiration Date"
                  type="date"
                  onChange={(e) => setExpirationDate(e.target.value)}
                  value={expirationDate}
                  required
                  autoComplete="off"
                />
                <CustomInput
                  label="Select Document"
                  type="file"
                  onChange={handleFileChange}
                  error={!docFile ? `Upload ${docuName} (.pdf,.doc,.docx)` : ""}
                />
              </div>
              <div className="mx-auto text-center mt-3 lg:col-span-2">
                <button
                  type="submit"
                  disabled={loadingSubmit ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loadingSubmit ? <Spinner /> : "Upload"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default FeaturedDocument;
