import React from 'react';
import ServiceTable from './ServiceTable';
import FirstForm from './FirstForm';
import Details from './Details';
import RepContact from './RepContact';
import Summary from './Summary';

const ReferralForme = () => {
  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Referral Form</h1>

      <FirstForm />

      <h2 className="text-xl font-bold mb-4">Participant Details</h2>
      
      <Details />

      <h2 className="text-xl font-bold mb-4">Primary Family or Representative Contact</h2>

      <RepContact />

      <h2 className="text-xl font-bold mb-4">Summary of Registration Groups Requested</h2>

      <Summary />

      <h2 className="text-xl font-bold mb-4">Services</h2>
      
      <ServiceTable />
    </div>
  );
};

export default ReferralForme;
