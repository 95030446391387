import React, { FormEvent, useEffect, useRef, useState } from "react";
import Hero from "./__component/Hero";
import SkillForm from "./__component/SkillForm";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";
import { RootState } from "../../store/store";
import { useParams } from "react-router-dom";
import { CompanyDetail } from "../../types/AuthData";
import { fetchClientInfo, fetchCompany, fetchParticipantSkilldoc } from "../../services/fetchServices";
import { useSelector } from "react-redux";
import { Client } from "../../store/slices/clientSlice";
import Spinner from "../../Components/Spinner";
import Date from "./__component/Date";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { SkillDevPlan } from "../../types/DataType";
import EditSkillDevelopment from "./EditSkillDevPlan";

const SkillDevelopment = () => {
  const { uid } = useParams();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  // const [loading, setLoading] = useState<boolean>(false);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };
  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchDoc()
  }, [uid]);

  const [goals, setGoals] = useState<string[]>(new Array(4).fill(''));
  const [supports, setSupports] = useState<string[]>(new Array(4).fill(''));
  const [deadlines, setDeadlines] = useState<string[]>(new Array(4).fill(''));
  const [completionDates, setCompletionDates] = useState<string[]>(new Array(4).fill(''));
  const [comment, setComment] = useState<string[]>(new Array(4).fill(''));
  const [workingOnIt, setWorkingOnIt] = useState<boolean[]>(new Array(4).fill(false));
  const [canDo, setCanDo] = useState<boolean[]>(new Array(4).fill(false));
  const [loading, setLoading] = useState<boolean>(false);
  const handleTextAreaChange = (value: string, index: number, type: 'goal' | 'support' | 'deadline' | 'comments') => {
    if (type === 'goal') {
      const updatedGoals = [...goals];
      updatedGoals[index] = value;
      setGoals(updatedGoals);
    } else if (type === 'support') {
      const updatedSupports = [...supports];
      updatedSupports[index] = value;
      setSupports(updatedSupports);
    } else if (type === 'deadline') {
      const updatedDeadlines = [...deadlines];
      updatedDeadlines[index] = value;
      setDeadlines(updatedDeadlines);
    }
    else if (type === 'comments') {
      const updatedComment = [...comment];
      updatedComment[index] = value;
      setComment(updatedComment);
    }
  };

  const handleDateChange = (value: string, index: number) => {
    const updatedDates = [...completionDates];
    updatedDates[index] = value;
    setCompletionDates(updatedDates);
  };

  const handleCheckboxChange = (checked: boolean, index: number, type: 'workingOnIt' | 'canDo') => {
    if (type === 'workingOnIt') {
      const updatedWorkingOnIt = [...workingOnIt];
      updatedWorkingOnIt[index] = checked;
      setWorkingOnIt(updatedWorkingOnIt);
    } else if (type === 'canDo') {
      const updatedCanDo = [...canDo];
      updatedCanDo[index] = checked;
      setCanDo(updatedCanDo);
    }
  };

  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);

  const [checker, setChecker] = useState<SkillDevPlan | null>(null)
  const [participantSkillDevForm, setParticipantSkillDevForm] = useState<SkillDevPlan>({
    skillDevelopmentPlanId: 0,
    goal: "",
    step1_learning: "",
    step1_Support: "",
    step1_Date_of_Achievement: "",
    step1_Working_Progress: false,
    step1_Done: false,
    step1_Comment: "",
    step1_Date_of_Completion: "",
    step2_learning: "",
    step2_Support: "",
    step2_Date_of_Achievement: "",
    step2_Working_Progress: false,
    step2_Done: false,
    step2_Comment: "",
    step2_Date_of_Completion: "",
    step3_learning: "",
    step3_Support: "",
    step3_Date_of_Achievement: "",
    step3_Working_Progress: false,
    step3_Done: false,
    step3_Comment: "",
    step3_Date_of_Completion: "",
    step4_learning: "",
    step4_Support: "",
    step4_Date_of_Achievement: "",
    step4_Working_Progress: false,
    step4_Done: false,
    step4_Comment: "",
    step4_Date_of_Completion: "",
    profileId: 0,
    document_Name: "",
    implementationDate: "",
    expirationDate: "",
    companyId: 0
  });

  const fetchDoc = async () => {
    const data = await fetchParticipantSkilldoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-107 P08 Skill development Plan"
    );
    setParticipantSkillDevForm({ ...data });
    setChecker(data)

  };

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setParticipantSkillDevForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    goals: [
      participantSkillDevForm.step1_learning,
      participantSkillDevForm.step2_learning,
      participantSkillDevForm.step3_learning,
      participantSkillDevForm.step4_learning
    ],
    supports: [
      participantSkillDevForm.step1_Support,
      participantSkillDevForm.step2_Support,
      participantSkillDevForm.step3_Support,
      participantSkillDevForm.step4_Support
    ],
    deadlines: [
      participantSkillDevForm.step1_Date_of_Achievement,
      participantSkillDevForm.step2_Date_of_Achievement,
      participantSkillDevForm.step3_Date_of_Achievement,
      participantSkillDevForm.step4_Date_of_Achievement
    ],
    completionDates: [
      participantSkillDevForm.step1_Date_of_Completion,
      participantSkillDevForm.step2_Date_of_Completion,
      participantSkillDevForm.step3_Date_of_Completion,
      participantSkillDevForm.step4_Date_of_Completion
    ],
    workingOnIt: [
      participantSkillDevForm.step1_Working_Progress,
      participantSkillDevForm.step2_Working_Progress,
      participantSkillDevForm.step3_Working_Progress,
      participantSkillDevForm.step4_Working_Progress
    ],
    canDo: [
      participantSkillDevForm.step1_Done,
      participantSkillDevForm.step2_Done,
      participantSkillDevForm.step3_Done,
      participantSkillDevForm.step4_Done
    ],
    comments: [
      participantSkillDevForm.step1_Comment,
      participantSkillDevForm.step2_Comment,
      participantSkillDevForm.step3_Comment,
      participantSkillDevForm.step4_Comment
    ]
  });

  useEffect(() => {
    setFormData({
      goals: [
        participantSkillDevForm.step1_learning,
        participantSkillDevForm.step2_learning,
        participantSkillDevForm.step3_learning,
        participantSkillDevForm.step4_learning
      ],
      supports: [
        participantSkillDevForm.step1_Support,
        participantSkillDevForm.step2_Support,
        participantSkillDevForm.step3_Support,
        participantSkillDevForm.step4_Support
      ],
      deadlines: [
        participantSkillDevForm.step1_Date_of_Achievement,
        participantSkillDevForm.step2_Date_of_Achievement,
        participantSkillDevForm.step3_Date_of_Achievement,
        participantSkillDevForm.step4_Date_of_Achievement
      ],
      completionDates: [
        participantSkillDevForm.step1_Date_of_Completion,
        participantSkillDevForm.step2_Date_of_Completion,
        participantSkillDevForm.step3_Date_of_Completion,
        participantSkillDevForm.step4_Date_of_Completion
      ],
      workingOnIt: [
        participantSkillDevForm.step1_Working_Progress,
        participantSkillDevForm.step2_Working_Progress,
        participantSkillDevForm.step3_Working_Progress,
        participantSkillDevForm.step4_Working_Progress
      ],
      canDo: [
        participantSkillDevForm.step1_Done,
        participantSkillDevForm.step2_Done,
        participantSkillDevForm.step3_Done,
        participantSkillDevForm.step4_Done
      ],
      comments: [
        participantSkillDevForm.step1_Comment,
        participantSkillDevForm.step2_Comment,
        participantSkillDevForm.step3_Comment,
        participantSkillDevForm.step4_Comment
      ]
    });
  }, [participantSkillDevForm]);

  const handleTextAreaEditChange = (value: string, index: number, field: keyof typeof formData) => {
    const updatedField = [...formData[field]];
    updatedField[index] = value;
    setFormData({ ...formData, [field]: updatedField });
  };

  const handleCheckboxEditChange = (checked: boolean, index: number, field: 'workingOnIt' | 'canDo') => {
    const updatedField = [...formData[field]];
    updatedField[index] = checked;
    setFormData({ ...formData, [field]: updatedField });
  };

  const handleDateEditChange = (value: string, index: number) => {
    const updatedCompletionDates = [...formData.completionDates];
    updatedCompletionDates[index] = value;
    setFormData({ ...formData, completionDates: updatedCompletionDates });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;

    const info = {
      goal: "",
      step1_learning: goals[0],
      step1_Support: supports[0],
      step1_Date_of_Achievement: deadlines[0],
      step1_Working_Progress: workingOnIt[0],
      step1_Done: canDo[0],
      step1_Comment: comment[0],
      step1_Date_of_Completion: completionDates[0],
      step2_learning: goals[1],
      step2_Support: supports[1],
      step2_Date_of_Achievement: deadlines[1],
      step2_Working_Progress: workingOnIt[1],
      step2_Done: canDo[1],
      step2_Comment: comment[1],
      step2_Date_of_Completion: completionDates[1],
      step3_learning: goals[2],
      step3_Support: supports[2],
      step3_Date_of_Achievement: deadlines[2],
      step3_Working_Progress: workingOnIt[2],
      step3_Done: canDo[2],
      step3_Comment: comment[2],
      step3_Date_of_Completion: completionDates[2],
      step4_learning: goals[3],
      step4_Support: supports[3],
      step4_Date_of_Achievement: deadlines[3],
      step4_Working_Progress: workingOnIt[3],
      step4_Done: canDo[3],
      step4_Comment: comment[3],
      step4_Date_of_Completion: completionDates[3],
      profileId: uid,
      document_Name: "PCD-107 P08 Skill development Plan",
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      companyId: user?.companyId
    }

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/SkillDevelopmentPlans/fill_skill_development_plan?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const info = {
      skillDevelopmentPlanId: participantSkillDevForm.skillDevelopmentPlanId,
      goal: "",
      step1_learning: formData.goals[0],
      step1_Support: formData.supports[0],
      step1_Date_of_Achievement: formData.deadlines[0],
      step1_Working_Progress: formData.workingOnIt[0],
      step1_Done: formData.canDo[0],
      step1_Comment: formData.comments[0],
      step1_Date_of_Completion: completionDates[0],
      step2_learning: formData.goals[1],
      step2_Support: formData.supports[1],
      step2_Date_of_Achievement: formData.deadlines[1],
      step2_Working_Progress: formData.workingOnIt[1],
      step2_Done: formData.canDo[1],
      step2_Comment: formData.comments[1],
      step2_Date_of_Completion: completionDates[1],
      step3_learning: formData.goals[2],
      step3_Support: formData.supports[2],
      step3_Date_of_Achievement: formData.deadlines[2],
      step3_Working_Progress: formData.workingOnIt[2],
      step3_Done: formData.canDo[2],
      step3_Comment: formData.comments[2],
      step3_Date_of_Completion: completionDates[2],
      step4_learning: formData.goals[3],
      step4_Support: formData.supports[3],
      step4_Date_of_Achievement: formData.deadlines[3],
      step4_Working_Progress: formData.workingOnIt[3],
      step4_Done: formData.canDo[3],
      step4_Comment: formData.comments[3],
      step4_Date_of_Completion: completionDates[3],
      profileId: uid,
      document_Name: "PCD-107 P08 Skill development Plan",
      implementationDate: participantSkillDevForm.implementationDate,
      expirationDate: participantSkillDevForm.expirationDate,
      companyId: user?.companyId
    }

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/SkillDevelopmentPlans/edit/${participantSkillDevForm.skillDevelopmentPlanId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <div>
      <Breadcrumb />

      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditSkillDevelopment
          companyOne={companyOne}
          clientDetail={clientDetail}
          participantSkillDevForm={participantSkillDevForm}
          formData={formData}
          handleCheckboxEditChange={handleCheckboxEditChange}
          handleDateEditChange={handleDateEditChange}
          handleTextAreaEditChange={handleTextAreaEditChange}
          handleEditSubmit={handleEditSubmit}
          loading={loading}
          handleInputDataChange={handleInputDataChange}
        />
      ) : (
        <form onSubmit={handleSubmit} >
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print this Document"
            documentTitle="Skill Development Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-4xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero clientDetail={clientDetail} companyOne={companyOne} />
            <div>
              <div className="flex items-center h-10 bg-gray-200  font-bold divide-x divide-gray-300">
                <div className="text-lg px-6">Daily care or life skill goal </div>
                <div className="text-lg px-6">I want to: </div>
              </div>
              <div className="flex items-center justify-between h-8 bg-gray-100  font-bold divide-x divide-gray-400">
                <div className=" px-2">Steps I need to take to achieve my goal</div>
                <div className=" px-2">Progress on achieving goal</div>
                <div className=" px-2">Comments on progress</div>
              </div>
              <SkillForm canDo={canDo} completionDates={completionDates} deadlines={deadlines} comment={comment}
                goals={goals} handleCheckboxChange={handleCheckboxChange} handleDateChange={handleDateChange}
                handleTextAreaChange={handleTextAreaChange} supports={supports} workingOnIt={workingOnIt} />
              <Date
                ExpirationDate={ExpirationDate}
                ImplementationDate={ImplementationDate}
              />
            </div>
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SkillDevelopment;
