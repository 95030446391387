import React, { FormEvent, useEffect, useRef, useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchStaffProgressNote,
  staffProgessNoteData,
  staffProgessNoteLoading,
} from "../../../store/slices/staffProgresNoteSlice";
import ReportCard from "./components/Card";
import dayjs from "dayjs";
import Spinner from "../../../Components/Spinner";
import { StaffProfileTypes } from "../../../types/AuthData";
import { Modal } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import fileImg from "../../../images/icon/EmptyState.png";

interface DataRow {
  companyID: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  endKm: number;
  followUp: string;
  imageFIle: string;
  imageURL: string;
  isCompleted: boolean;
  position: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  progress: string;
  progressNoteId: number;
  report: string;
  staff: string;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
}
import PaginationComponent from "../../../Components/CustomPagination";

const ShiftReport = () => {
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchStaffProgressNote(staffProfile.fullName));
  }, [dispatch]);

  const staffReport = useSelector(staffProgessNoteData);
  const loadingData = useSelector(staffProgessNoteLoading);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showReportModal, setReportShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(staffReport.length / itemsPerPage);
  const displayData = staffReport.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const [progressDetails, setProgressDetails] = useState<DataRow>({
    companyID: 0,
    date: "",
    dateCreated: "",
    dateModified: "",
    endKm: 0,
    followUp: "",
    imageFIle: "",
    imageURL: "",
    isCompleted: false,
    position: "",
    profileId: 0,
    progress: "",
    progressNoteId: 0,
    report: "",
    staff: "",
    staffId: 0,
    startKm: 0,
    userCreated: "",
    userModified: "",
    profile: {
      profileId: 0,
      firstName: "",
      surName: "",
      middleName: "",
      clientId: 0,
      email: "",
      phoneNumber: "",
      gender: "",
      fullName: "",
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProgressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const EditDetail = async (id: string) => {
    setLoading(true);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/ProgressNotes/${id}`);
      setProgressDetails({ ...data });
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        setLoading(false);
      } else {
        toast.error("An error occurred");
        setLoading(false);
      }
    }
  };

  const ReportDetail = async (id: string) => {
    setLoading(true);
    setReportShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/ProgressNotes/${id}`);
      setProgressDetails({ ...data });
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        setLoading(false);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const info = {
      ...progressDetails,
    };

    try {
      const { data } = await axiosInstance.post(
        `/ProgressNotes/edit/${progressDetails.progressNoteId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoadingSubmit(false);
      dispatch(fetchStaffProgressNote(staffProfile.fullName));
      setShowModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const topOfPageRef = useRef<HTMLInputElement>(null);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
    topOfPageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <>
      <SEO
        title={` Staff Progress Note`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div
        className="flex justify-between items-center flex-wrap"
        ref={topOfPageRef}
      >
        <h2 className="font-bold text-2xl text-black w-auto">Progress Note</h2>
      </div>
      {staffReport.length <= 0 && loadingData && <Spinner color="#071952" />}

      {!loadingData && staffReport.length <= 0 && (
        <div className="  flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
          <img src={fileImg} alt="file upload icon" width={200} height={300} />

          <strong className="text-xl">No Data Available</strong>
        </div>
      )}

      <div className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {staffReport.length > 0 &&
            displayData.map((item) => (
              <ReportCard
                key={item.progressNoteId}
                client={item.profile.fullName}
                date={dayjs(item.date).format("D/M/YYYY h:mm A")}
                dateCreated={dayjs(item.dateCreated).format("D/M/YYYY h:mm A")}
                dateModified={dayjs(item.dateModified).format(
                  "D/M/YYYY h:mm A"
                )}
                onView={() => ReportDetail(item.progressNoteId)}
                onEdit={() => EditDetail(item.progressNoteId)}
              />
            ))}
        </div>
        {pageCount > 1 && (
          <PaginationComponent
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
        <br />
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Edit Progress Note Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <form action="">
                <div className="px-2 py-2">
                  <label htmlFor="report" className="font-bold">
                    Report{" "}
                  </label>
                  <textarea
                    id="report"
                    rows={3}
                    name="report"
                    autoComplete="off"
                    value={progressDetails.report || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
                <div className="px-2 py-2">
                  <label htmlFor="progress" className="font-bold">
                    Progress{" "}
                  </label>
                  <textarea
                    id="progress"
                    rows={3}
                    name="progress"
                    autoComplete="off"
                    value={progressDetails.progress || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
                <div className="px-2 py-2">
                  <label htmlFor="followUp" className="font-bold">
                    Follow Up{" "}
                  </label>
                  <textarea
                    id="followUp"
                    rows={3}
                    name="followUp"
                    autoComplete="off"
                    value={progressDetails.followUp || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
              </form>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loadingSubmit}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loadingSubmit ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showReportModal}
        onClose={() => setReportShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Progress Note Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div className="">
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Report:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {progressDetails.report}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Progress:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {progressDetails.progress}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Follow Up:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {progressDetails.followUp}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(progressDetails.dateCreated).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  User Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(progressDetails.userCreated).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Modified
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(progressDetails.dateModified).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  User Modified
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {progressDetails.userModified}
                </td>
              </tr>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ShiftReport;
