import React, { useEffect, useState } from 'react'
import CustomInput from '../../../Components/Input/CustomInput'
import Spinner from '../../../Components/Spinner';
import Breadcrumb from '../../../Components/label/BreadCrumb';
import { CompanyDetail, PackageProps } from '../../../types/AuthData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { fetchCompany, fetchPackages } from '../../../services/fetchServices';
import { FaRegEdit } from 'react-icons/fa';
import EditCompanyProfile from './components/EditCompanyProfile';

const CompanyProfile = () => {
    const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [packages, setPackages] = useState<PackageProps[]>([])
    const [companyOne, setCompanyOne] = useState<CompanyDetail | null>(null)
    const [editedCompany, setEditedCompany] = useState<
        CompanyDetail
    >({
        abn: "",
        companyAddress: "",
        companyDetails: "",
        companyEmail: "",
        companyHead: "",
        companyId: 0,
        companyLogo: "",
        companyLogoFile: "",
        companyName: "",
        companyPhone: "",
        companyState: "",
        expirationDate: "",
        dateCreated: "",
        isApproved: false,
        signature: "",
        signatureFile: null,
        packagesId: 0,
        subscribtionDate: "",
        website: "",
        generalEmail: "",
        payrollEmail: "",
        providerNumber: "",
        supportEmail: "",
        packages: {
            amount: 0,
            dateCreated: "",
            duration: 0,
            features: "",
            package: "",
            packageDetails: "",
            packageLogo: "",
            packagesId: 0,
            userCreated: ""
        }
    });
    const [infoModal, setInfoModal] = useState(false);
    useEffect(() => {
        handleCompanyFetch();
        handleFetch();
    }, [user?.companyId])
    const handleCompanyFetch = async () => {
        // setLoading(true);
        if (user?.companyId) {
            const data = await fetchCompany(user?.companyId);
            setEditedCompany(data)
            setCompanyOne({ ...data });
            setLoadingCompany(false)
        }
        // setLoading(false);
    };
    const handleFetch = async () => {
        const data = await fetchPackages();
        setPackages(data)
    }



    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setEditedCompany((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [signature, setSignature] = useState<Blob | null>(null);
    const handleSignatureSave = (signatureBlob: Blob) => {

        setSignature(signatureBlob);
    };
    // <input type="file" accept="image/jpeg, image/png" style={styles.main} onChange={handlechange} />

    return (
        <>
            <Breadcrumb />
            <div className="my-5">
                {loadingCompany && (
                    <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-auto max-w-lg mx-auto">
                            <div
                                className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
                border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                            >
                                <Spinner color="#071952" />
                            </div>
                        </div>

                        <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                    </div>
                )}
                <div
                    className="space-y-1  items-center shadow-md rounded-md  bg-white"
                >
                    <div className="bg-gray-200 text-center p-2 flex relative justify-between items-center text-lg font-semibold rounded-t-md ">
                        <span>Company Profile</span>{" "}
                        <button
                            onClick={() => setInfoModal(true)}
                            className='absolute top-1 right-2 p-2 rounded-full bg-white shadow'><FaRegEdit /></button>
                    </div>
                    {companyOne?.companyLogo && <div className=" lg:space-x-4  text-right flex justify-center p-4 ">
                        <img
                            className="w-28"
                            src={companyOne.companyLogo}
                            alt="Company Logo"
                        />
                    </div>}
                    <div className="p-4 grid grid-cols-1 gap-4 md:grid-cols-2  lg:gap-8">


                        <CustomInput
                            label="Company Head"
                            value={companyOne?.companyHead}
                            type="text"
                            readOnly
                        />
                        <CustomInput
                            label="Company Name"
                            type="text"
                            value={companyOne?.companyName}
                            readOnly
                        />
                        <CustomInput
                            label="Company Email"
                            type="text"
                            value={companyOne?.companyEmail}
                            readOnly
                        />
                        <CustomInput
                            label="Company Address"
                            type="text"
                            value={companyOne?.companyAddress}
                            readOnly
                        />
                        <CustomInput
                            label="Company Phone"
                            type="text"
                            value={companyOne?.companyPhone}
                            readOnly
                        />
                        <CustomInput
                            label="Company Website"
                            type="text"
                            value={companyOne?.website}
                            readOnly
                        />
                        <CustomInput
                            label="Payroll Email"
                            type="text"
                            value={companyOne?.payrollEmail}
                            readOnly
                        />
                        <CustomInput
                            label="Support Email"
                            type="text"
                            value={companyOne?.supportEmail}
                            readOnly
                        />
                        <CustomInput
                            label="General Email"
                            type="text"
                            value={companyOne?.generalEmail}
                            readOnly
                        />
                        <CustomInput
                            label="NDIS Provider Number"
                            type="text"
                            value={companyOne?.providerNumber}
                            readOnly
                        />

                    </div>
                    <div className=" p-4">
                        {!companyOne?.signature && <button
                            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                            type="button"
                            onClick={() => setInfoModal(true)}
                        >
                            Add Signature
                        </button>}


                        {companyOne?.signature && <span className="text-center font-semibold">Signature:</span>}


                        {companyOne?.signature && (
                            <img
                                src={companyOne?.signature}
                                alt="Signature"
                                className="img-thumbnail  block border rounded border-gray-300 bg-gray-200 p-1 overflow-hidden"
                                style={{
                                    display: "block",
                                    width: "150px",
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <EditCompanyProfile
                infoModal={infoModal}
                setInfoModal={setInfoModal}
                handleInputChange={handleInputChange}
                companyOne={editedCompany}
                packages={packages}
                signature={signature}
                handleCompanyFetch={handleCompanyFetch}
                handleSignatureSave={handleSignatureSave}
                setSignature={setSignature}
            />

        </>
    )
}

export default CompanyProfile