import React, { FormEvent, useEffect, useRef, useState } from "react";
import Hero from "./___component/Hero";
import GoalForm from "./___component/GoalForm";
import Section1 from "./___component/Section1";
import ResponsibilityTable from "./___component/ResponsibilityTable";
import Progress from "./___component/Progress";
import PrintComponent from "../PrintTab";
import { useParams } from "react-router-dom";
import { Client } from "../../store/slices/clientSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { CompanyDetail } from "../../types/AuthData";
import { fetchClientInfo, fetchCompany, fetchSupportGoaldoc } from "../../services/fetchServices";
import Spinner from "../../Components/Spinner";
import Date from "./___component/Date";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Breadcrumb from "../../Components/label/BreadCrumb";
import { SupportGoalPlanData } from "../../types/DataType";
import EditSupportGoal from "./EditSupportGoal";

type RowKeys = "date" | "actionRef" | "progressUpdate";

interface Row {
  date: string;
  actionRef: string;
  progressUpdate: string;
}

const SupportGoal = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchDoc()
  }, [uid])

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);

  const [attendees, setAttendees] = useState(["", "", ""]);

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newAttendees = [...attendees];
    newAttendees[index] = event.target.value;
    setAttendees(newAttendees);
  };

  const [goal, setGoal] = useState("");
  const [strategies, setStrategies] = useState(["", "", "", "", ""]);

  const handleGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGoal(event.target.value);
  };

  const handleStrategyChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newStrategies = [...strategies];
    newStrategies[index] = event.target.value;
    setStrategies(newStrategies);
  };

  const [actions, setActions] = useState(["", "", "", ""]);
  const [responsibilities, setResponsibilities] = useState(["", "", "", ""]);
  const [timeframes, setTimeframes] = useState(["", "", "", ""]);

  const handleActionChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newActions = [...actions];
    newActions[index] = event.target.value;
    setActions(newActions);
  };

  const handleResponsibilityChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newResponsibilities = [...responsibilities];
    newResponsibilities[index] = event.target.value;
    setResponsibilities(newResponsibilities);
  };

  const handleTimeframeChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newTimeframes = [...timeframes];
    newTimeframes[index] = event.target.value;
    setTimeframes(newTimeframes);
  };

  const [rows, setRows] = useState<Row[]>([
    { date: "", actionRef: "", progressUpdate: "" },
    { date: "", actionRef: "", progressUpdate: "" },
    { date: "", actionRef: "", progressUpdate: "" },
    { date: "", actionRef: "", progressUpdate: "" },
    { date: "", actionRef: "", progressUpdate: "" },
  ]);

  const handleCellChange = (rowIndex: number, cellName: RowKeys, value: string) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][cellName] = value;
    setRows(updatedRows);
  };

  const [checker, setChecker] = useState<SupportGoalPlanData | null>(null)
  const [details, setDetails] = useState<SupportGoalPlanData>({
    supportGoalPlanId: 0,
    attendees: "",
    goal: "",
    strategies: "",
    goalAction: [
      {
        action_Required: "",
        responsibility: "",
        timeframe: ""
      }
    ],
    goalProgress: [
      {
        date: "",
        action: "",
        progress_Update: ""
      }
    ],
    profileId: 0,
    document_Name: "",
    implementationDate: "",
    expirationDate: "",
    companyId: 0
  })

  const fetchDoc = async () => {
    const data = await fetchSupportGoaldoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-108 P09 Support Goal Plan"
    );
    setDetails({ ...data });
    setChecker(data)

  };

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputDChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAttendees = details.attendees.split(', ').map((attendee, idx) =>
      idx === index ? event.target.value : attendee
    );
    setDetails((prevDetails) => ({
      ...prevDetails,
      attendees: updatedAttendees.join(', ')
    }));
  };


  const handleInputActionChange = (type: 'action_Required' | 'responsibility' | 'timeframe', index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedActions = details.goalAction.map((action, idx) => {
      if (idx === index) {
        return {
          ...action,
          [type]: event.target.value,
        };
      }
      return action;
    });

    setDetails((prevDetails) => ({
      ...prevDetails,
      goalAction: updatedActions,
    }));
  };

  const handleCellEditChange = (rowIndex: number, cellName: "date" | "actionRef" | "progressUpdate", value: string) => {
    const updatedGoalProgress = details.goalProgress.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [cellName]: value,
        };
      }
      return row;
    });

    setDetails((prevDetails) => ({
      ...prevDetails,
      goalProgress: updatedGoalProgress,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // const expirationDateValue = ExpirationDate.current?.value;
    setLoading(true);
    const info = {
      attendees: "Dele, Ayo",
      goal: "Learing Skill",
      strategies: "N/A, N/A",
      goalAction: [
        {
          action_Required: "N/A",
          responsibility: "N/A",
          timeframe: "N/A"
        },
        {
          action_Required: "N/A",
          responsibility: "N/A",
          timeframe: "N/A"
        }
      ],
      goalProgress: [
        {

          date: "N/A",
          "action": "N/A",
          "progress_Update": "N/A"
        },
        {

          date: "N/A",
          action: "N/A",
          progress_Update: "N/A"
        }
      ],
      profileId: "97",
      document_Name: "PCD-108 P09 Support Goal Plan",
      implementationDate: "2024-12-23",
      companyId: "2"
      // attendees: attendees.filter(strategy => strategy.trim()).join(", "),
      // goal: goal,
      // strategies: strategies.filter(strategy => strategy.trim()).join(", "),
      // goalAction: [
      //   {
      //     action_Required: actions.filter(strategy => strategy.trim()).join(", "),
      //     responsibility: responsibilities.filter(strategy => strategy.trim()).join(", "),
      //     timeframe: timeframes.filter(strategy => strategy.trim()).join(", ")
      //   }
      // ],
      // goalProgress: rows.map((row) => ({
      //   date: row.date,
      //   action: row.actionRef,
      //   progress_Update: row.progressUpdate,
      // })),
      // profileId: uid,
      // document_Name: "PCD-108 P09 Support Goal Plan",
      // implementationDate: ImplementationDate.current?.value,
      // ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      // companyId: user?.companyId
    }

    try {
      const { data } = await axiosInstance.post(
        `/SupportGoalPlans/fill_supportgoalplan?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}


      {checker ? (
        <EditSupportGoal
          companyOne={companyOne}
          clientDetail={clientDetail}
          details={details}
          handleInputDataChange={handleInputDataChange}
          handleInputDChange={handleInputDChange}
          actions={details.goalAction.map(action => action.action_Required)}
          responsibilities={details.goalAction.map(action => action.responsibility)}
          timeframes={details.goalAction.map(action => action.timeframe)}
          handleActionChange={(index, event) => handleInputActionChange('action_Required', index, event)}
          handleResponsibilityChange={(index, event) => handleInputActionChange('responsibility', index, event)}
          handleTimeframeChange={(index, event) => handleInputActionChange('timeframe', index, event)}
          rows={details.goalProgress.map(progress => ({
            date: progress.date,
            actionRef: progress.action,
            progressUpdate: progress.progress_Update
          }))}
          handleCellEditChange={handleCellEditChange}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print this Document"
            documentTitle="Consent Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero companyOne={companyOne} />
            <GoalForm clientDetail={clientDetail} attendees={attendees} handleInputChange={handleInputChange} />
            <Section1 goal={goal} handleGoalChange={handleGoalChange} handleStrategyChange={handleStrategyChange}
              strategies={strategies}
            />
            <ResponsibilityTable actions={actions} handleActionChange={handleActionChange} handleResponsibilityChange={handleResponsibilityChange}
              handleTimeframeChange={handleTimeframeChange} responsibilities={responsibilities} timeframes={timeframes} />
            <Progress handleCellChange={handleCellChange} rows={rows} />
            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : " Submit"}
            </button>
          </div>
        </form>
      )}
    </div>

  );
};

export default SupportGoal;
