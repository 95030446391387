import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import AuthLayout from "./Pages/Auth";
import AdminLayout from "./Layout/AdminLayout";
import SubLayout from "./Pages/Pricing";
import PaymentLayout from "./Pages/Payment";
import SetupLayout from "./Pages/Setup";
import Maintainance from "./Pages/Maintainance";
import PDFLayout from "./Pages/PDF";
import { RootState } from "./store/store";
import CropTest from "./Pages/CropTest";

const RoleBasedRedirect = () => {
  const { isAuthenticated, user } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  }));

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  switch (user?.role) {
    case "Staff":
      return <Navigate to="/app/staff/dashboard" replace />;
    case "CompanyAdmin":
    case "Administrator":
      return <Navigate to="/app/dashboard" replace />;
    case "Client":
      return <Navigate to="/app/client/dashboard" replace />;
    default:
      return <Navigate to="/auth/sign-in" replace />;
  }
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RoleBasedRedirect />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/pdf/*" element={<PDFLayout />} />
        <Route path="/payment/*" element={<PaymentLayout />} />
        <Route path="/subscription/*" element={<SubLayout />} />
        <Route path="/setup/*" element={<SetupLayout />} />
        <Route path="/app/*" element={<AdminLayout />} />
        <Route path="/not-implemented" element={<Maintainance />} />
        <Route path="/upload-test" element={<CropTest />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
