import React from "react";
import { Link } from "react-router-dom";

interface Props {
  icon: JSX.Element;
  title: string;
  value: number;
  link?: string;
  detail?: string;
}

function formatNumber(num: number): string {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // For millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k"; // For thousands
  } else {
    return num.toString(); // Convert number to string for consistency
  }
}

const DashboardCards = ({ icon, title, value, link, detail }: Props) => {
  const formattedValue = formatNumber(value);

  return (
    <>
      <div className="rounded-lg bg-white py-3 px-3 shadow-sm border dark:border-gray-200 dark:bg-slate-800 overflow-clip">
        <div className="flex h-14 w-14 items-center gap-3">
          <div className="mt-5">{icon}</div>

          <div className="flex-col mt-5 text-nowrap ">
            <div className="text-title-md font-bold text-xl text-black dark:text-white">
              {formattedValue}
            </div>
            <h4 className="text-xs text-black font-normal truncate">{title}</h4>
          </div>
        </div>
        <div className="mt-4 text-xs">{detail}</div>

        {link && (
          <div className="mt-4 flex items-end text-xs justify-end">
            <Link to={link}>view all</Link>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardCards;
