import React, { useEffect, useRef, useState } from 'react'
import SupportPlanReview from './__component/Main'
import Breadcrumb from '../../Components/label/BreadCrumb';
import PrintComponent from '../PrintTab';
import Hero from './__component/Hero';
import { useParams } from 'react-router-dom';
import { fetchClientInfo, fetchClientManagerProfle, fetchCompany } from '../../services/fetchServices';
import { CompanyDetail } from '../../types/AuthData';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Client } from '../../store/slices/clientSlice';
import Spinner from '../../Components/Spinner';
import { Staff } from '../../store/slices/staffSlice';

const SupportPlan = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [careManager, setCareManager] = useState<Staff | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  // const [checker, setChecker] = useState<ParticipantForm | null>(null)
  // const [participantForm, setParticipantForm] = useState<ParticipantForm>({
  //   participantProfileId: 0,
  //   profileId: 0,
  //   document_Name: "",
  //   dietary_Needs: "",
  //   mobility_Needs: "",
  //   implementationDate: "",
  //   expirationDate: "",
  //   communication_Preferences: "",
  //   medication: "",
  //   important_Things: "",
  //   preferred_Activities: "",
  //   dislikes: "",
  //   companyId: 0
  // });

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchClientManager()
    // fetchDoc()
  }, [uid, clientDetail?.careManager])

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const fetchClientManager = async () => {
    if (clientDetail?.careManager) {
      const data = await fetchClientManagerProfle(
        Number(user?.companyId),
        clientDetail?.careManager
      );
      setCareManager(data);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const [attendeeValues, setAttendeeValues] = useState<string[]>([""]);

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...attendeeValues];
    newValues[index] = value;
    setAttendeeValues(newValues);
  };

  const handleAddRow = () => {
    setAttendeeValues([...attendeeValues, ""]);
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Support Plan Review Form"
      />
      <div ref={componentRef} className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white">
        <Hero companyOne={companyOne} />
        <SupportPlanReview attendeeValues={attendeeValues} handleInputChange={handleInputChange}
          handleAddRow={handleAddRow} clientDetail={clientDetail} careManager={careManager}
        />
      </div>
    </div>
  )
}

export default SupportPlan