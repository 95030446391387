import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes, { RouteProps } from "../Routes";
import { useSelector } from "react-redux";
import SideBar from "../Components/Sidebar";
import Header from "../Components/Header";
import FallBack from "../Pages/FallBack";
import { RootState } from "../store/store";
import Support from "../Components/CustomerSupport";

const AdminLayout: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [open, setOpen] = useState<boolean>(true);


    const getRoutes = (routes: RouteProps[]) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/app") {
                return (
                    <Route
                        path={`/${prop.path}`}
                        element={
                            isAuthenticated ? (
                                prop.component
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };


    return (


        <div className='dark:bg-slate-800 dark:text-bodydark max-w-full mx-auto'>
            {/* <!-- ===== Page Wrapper Start ===== --> */}
            <div className='flex h-screen overflow-hidden bg-[#F3F4F6] relative' >
                {/* <!-- ===== Sidebar Start ===== --> */}
                <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} open={open} />
                {/* <!-- ===== Sidebar End ===== --> */}

                {/* <!-- ===== Content Area Start ===== --> */}
                <div className='relative flex flex-1 flex-col  overflow-x-hidden'>
                    {/* <!-- ===== Header Start ===== --> */}
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} open={open} setOpen={setOpen} />
                    {/* <!-- ===== Header End ===== --> */}

                    {/* <!-- ===== Main Content Start ===== --> */}
                    <main>
                        <div className='mx-auto p-4 md:p-6  2xl:p-10 bg-slate-100 h-screen '>
                            <React.Suspense fallback={<FallBack />}>
                                <Routes>
                                    {getRoutes(routes)}
                                    <Route path="/" element={<Navigate to="/" />} />
                                </Routes>
                            </React.Suspense>
                        </div>
                    </main>
                    {/* <!-- ===== Main Content End ===== --> */}
                    <Support />
                </div>
                {/* <!-- ===== Content Area End ===== --> */}
            </div>
            {/* <!-- ===== Page Wrapper End ===== --> */}
        </div>



    );
};

export default AdminLayout;
