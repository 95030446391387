import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { useDispatch } from "react-redux";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "admin",
    "chart",
    "client",
    "activity",
    "allAdminAttendance",
    "allStaffAttendance",
    "allTimeSheet",
    "careManager",
    "clientAidAndEquip",
    "clientDailyLiving",
    "clientProgressNote",
    "clientSchedule",
    "clientShift",
    "dashboard",
    "document",
    "formTemplate",
    "holiday",
    "inbox",
    "integration",
    "progressNote",
    "refferal",
    "role",
    "savedInvoice",
    "serviceProvider",
    "shiftAttendance",
    "shiftRoster",
    "splittedAttendance",
    "staff",
    "staffAttendance",
    "staffAvailability",
    "staffShift",
    "staffTimeSheet",
    "supportSchedule",
    "supportType",
    "ticket",
    "time",
    "user",
    "staffProgessNote",
    "timeSheet",
    "userTicket",
    "staffProfile",
    "shiftReport",
    "staffShiftReport",
    "staffDailyReport",
    "clientSupportSchedule",
    "serviceProviderClient",
    "clientRep",
    "sent",
    "expenditure",
    "knowledgeBase",
    "clientShiftReport",
    "clientReps",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Export the store as well
export { store };

// Export the setupListeners function if you need it
setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
