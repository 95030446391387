import React from 'react'

const RepContact = () => {
  return (
    <div>
        <table className="w-full mb-6">
        <tbody>
          <tr>
            <td className="border px-4 py-2 font-bold">Name:</td>
            <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded" /></td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Relationship:</td>
            <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded" /></td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Contact number:</td>
            <td className="border px-4 py-2"><input type="tel" className="w-full p-2 border border-gray-300 rounded" /></td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Email:</td>
            <td className="border px-4 py-2"><input type="email" className="w-full p-2 border border-gray-300 rounded" /></td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Is this person an appointed Guardian?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="guardian" className="mr-2" value="Yes" /> Yes
                </label>
                <label>
                  <input type="radio" name="guardian" className="mr-2" value="No" /> No
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default RepContact