import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { StaffDataRow, StaffProfileTypes } from "../../../types/AuthData";
import { fetchStaffInfo } from "../../../services/fetchServices";
import man from "../../../images/user/man.png";
import CardHeader from "../../Admin/Profiles/components/CardHeader";
import { Modal } from "rsuite";
import EditDropDown from "../../../Components/menu/EditDropDown";
import CardNameTag from "../../Admin/Profiles/components/CardNameTag";
import { isAxiosError } from "axios";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import WebcamCapture from "../../Admin/Profiles/components/CapturePicture";
import Spinner from "../../../Components/Spinner";
import StaffEditComp from "../../Admin/Profiles/components/StaffEditComp";
import StaffEmergComp from "../../Admin/Profiles/components/StaffEmergComp";
import StaffOtherComp from "../../Admin/Profiles/components/StaffOtherComp";
import dayjs from "dayjs";
import {
  fetchStaffProfile,
  selectProfileData,
} from "../../../store/slices/staffProfileSlice";
import SignatureComponent from "../../../Components/Signature";
import CropUploader from "../../../Components/PictureUpload/CropUploader";

const Profile = () => {
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
  const [emergencyModal, setEmergencyModal] = useState<boolean>(false);
  const [othersModal, setOthersModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [editedProfile, setEditedProfile] = useState<StaffDataRow>({
    aboutMe: "",
    accountName: "",
    accountNumber: 0,
    address: "",
    adm_Designations: "",
    adm_DesignationsId: "",
    auditApproved: false,
    australianCitizen: "",
    bankName: "",
    branch: "",
    bsb: "",
    city: "",
    company: "",
    companyId: 0,
    country: "",
    dateCreated: "",
    dateJoined: "",
    dateModified: "",
    dateOfBirth: "",
    email: "",
    employeeId: "",
    employmentType: "",
    facebook: "",
    firstName: "",
    fullName: "",
    gender: "",
    github: "",
    handbook: "",
    imageFile: "",
    imageUrl: "",
    instagram: "",
    isActive: false,
    isAdmin: false,
    isCompleted: false,
    isDocumentUploaded: false,
    kinAddress: "",
    kinCity: "",
    kinCountry: "",
    kinEmail: "",
    kinPhoneNumber: 0,
    kinPostcode: "",
    kinState: "",
    level: "",
    linkedIn: "",
    maxStaffId: "",
    middleName: "",
    nextOfKin: "",
    offerLetter: "",
    payDay: 0,
    payRate: "",
    phoneNumber: "",
    position: "",
    postcode: "",
    profession: "",
    relationship: "",
    salary: 0,
    staffId: 0,
    state: "",
    suburb: "",
    superForm: "",
    supportPosition: "",
    surName: "",
    taxFile: "",
    twitter: "",
    userCreated: "",
    userModified: "",
    xerolink: "",
    youtube: "",
    signatureUrl: null,
    signatureFile: null,
  });

  const handleFetchStaff = async () => {
    const data = await fetchStaffInfo(staffProfile?.staffId.toString());
    setEditedProfile({ ...data });
  };
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    handleFetchStaff();
    dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
  }, []);

  const profile = useSelector(selectProfileData);

  const [loading1, setLoading1] = useState<boolean>(false);
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);

  const handleCapture = async (imageBlob: Blob | null) => {
    if (imageBlob) {
      // Convert Blob to File
      const imageFile = new File([imageBlob], "captured-image.jpg", {
        type: imageBlob.type, // Preserving the MIME type
        lastModified: Date.now(), // Setting last modification time
      });

      if (imageFile) {
        setLoading1(true);
        const formData = new FormData();

        if (imageFile) {
          formData.append("imageFile", imageFile);
        }
        for (const key in editedProfile) {
          const value = editedProfile[key as keyof typeof editedProfile];
          if (value === null) {
            formData.append(key, ""); // Pass empty string if value is null
          } else {
            formData.append(key, value.toString());
          }
        }

        try {
          const { data } = await axiosInstance.post(
            `/Staffs/edit/${staffProfile.staffId}?userId=${user?.uid}`,
            formData
          );
          toast.success(data.message);
          setLoading1(false);
          setCameraModalOpen(false);
          handleFetchStaff();
          dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // console.log(error);;
          } else {
            toast.error("An error occurred");
          }
          setLoading1(false);
        } finally {
          setLoading1(false);
        }
      }

      // Close modal
    } else {
      setCameraModalOpen(false);
    }
  };

  const handleCroppedImage = async (croppedImage: Blob) => {
    const formData = new FormData();
    formData.append("imageFile", croppedImage, "cropped_image.jpg");

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      setLoading1(true);
      const { data } = await axiosInstance.post(
        `/Staffs/edit/${staffProfile.staffId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitData = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const formData = new FormData();

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      const { data } = await axiosInstance.post(
        `/Staffs/edit/${staffProfile.staffId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      setPersonalInfoModal(false);
      setEmergencyModal(false);
      setOthersModal(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const [signature, setSignature] = useState<string | null>(null);
  const [loadingSig, setloadingSig] = useState(false);

  const handleSignatureSave = async (signatureBlob: Blob) => {
    // Clean up the previous signature to prevent memory leaks
    if (signature) URL.revokeObjectURL(signature);

    // Create a new URL for the new signature blob and store it in state
    const signatureUrl = URL.createObjectURL(signatureBlob);
    setSignature(signatureUrl);

    if (signatureBlob) {
      // Convert Blob to File
      const imageFile = new File([signatureBlob], "signature.png", {
        type: signatureBlob.type, // Preserving the MIME type
        lastModified: Date.now(), // Setting last modification time
      });

      if (imageFile) {
        setloadingSig(true);
        const formData = new FormData();

        if (imageFile) {
          formData.append("signatureFile", imageFile);
        }
        for (const key in editedProfile) {
          const value = editedProfile[key as keyof typeof editedProfile];
          if (value === null) {
            formData.append(key, ""); // Pass empty string if value is null
          } else {
            formData.append(key, value.toString());
          }
        }

        try {
          const { data } = await axiosInstance.post(
            `/Staffs/edit/${staffProfile.staffId}?userId=${user?.uid}`,
            formData
          );
          toast.success(data.message);
          setloadingSig(false);
          dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
          handleFetchStaff();
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // console.log(error);;
          } else {
            toast.error("An error occurred");
          }
          setloadingSig(false);
        } finally {
          setloadingSig(false);
        }
      }

      // Close modal
    } else {
      console.log("No image captured");
    }
  };

  useEffect(() => {
    // Clean up the signature URL when the component unmounts
    return () => {
      if (signature) {
        URL.revokeObjectURL(signature);
      }
    };
  }, [signature]); // Dependency on 'signature' ensures cleanup runs if it changes

  return (
    <>
      <SEO
        title={`${editedProfile.fullName} - Profile`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div>
        <div className="space-y-8 py-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
            <div className="border-2 rounded-lg">
              <CardHeader label="Profile" />
              <div className="flex gap-2 flex-col justify-center items-center py-4">
                <div className="flex flex-col gap-1 items-center">
                  <div className="flex justify-center  w-24 h-24 overflow-hidden border rounded-full">
                    <img
                      src={profile?.imageUrl}
                      alt="img"
                      className=" w-full h-full object-cover"
                    />
                  </div>
                  <EditDropDown>
                    <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                      <button
                        onClick={() => setCameraModalOpen(true)}
                        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                      >
                        Take Photo
                      </button>

                      <CropUploader
                        onCroppedImage={handleCroppedImage}
                        loading={loading1}
                      />
                      {profile?.signatureUrl && (
                        <SignatureComponent
                          onSignatureSave={handleSignatureSave}
                          text="Change Signature"
                        />
                      )}
                    </div>
                  </EditDropDown>
                </div>
                {/* <Avatar size="xxl" circle /> */}
                <p className="font-bold text-sm text-black">
                  {profile?.fullName}
                </p>
                <p className=" text-sm text-black">
                  <span className="font-bold">Staff ID:</span>{" "}
                  <span>
                    {!profile?.maxStaffId ? "N/A" : profile?.maxStaffId}
                  </span>
                </p>
                <p className=" text-sm ">{profile?.phoneNumber}</p>

                <div className="border text-center ">
                  {loadingSig && <Spinner />}
                  {!profile?.signatureUrl && (
                    <SignatureComponent
                      onSignatureSave={handleSignatureSave}
                      text="Add Signature"
                    />
                  )}
                  {profile?.signatureUrl && (
                    <span className="text-center font-semibold">
                      Signature:
                    </span>
                  )}
                  {profile?.signatureUrl && (
                    <img
                      src={profile?.signatureUrl}
                      alt="Signature"
                      className="img-thumbnail  block border rounded border-gray-300 bg-gray-200 p-1 overflow-hidden"
                      style={{
                        display: "block",
                        width: "140px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <Modal
              open={photoModal}
              onClose={() => setPhotoModal(false)}
              role="alertdialog"
              autoFocus
              size={"xs"}
            >
              <Modal.Header></Modal.Header>
              <Modal.Body>
                <div className="w-56 h-56 overflow-hidden mx-auto">
                  <img
                    src={editedProfile.imageUrl || man}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal
              open={isCameraModalOpen}
              onClose={() => setCameraModalOpen(false)}
              role="alertdialog"
              backdrop="static"
              autoFocus
              size={"xs"}
            >
              <Modal.Header>
                <Modal.Title className="font-bold">Take photo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <WebcamCapture onCapture={handleCapture} loading={loading1} />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <div className=" lg:col-span-2 space-y-2">
              <div className="border-2 rounded-lg">
                <CardHeader
                  label="Personal Information"
                  showEditIcon
                  onEdit={() => setPersonalInfoModal(true)}
                />
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-4">
                  <CardNameTag label="Phone" name={profile?.phoneNumber} />
                  <CardNameTag label="Email" name={profile?.email} />
                  <CardNameTag label="Gender" name={profile?.gender} />
                  <CardNameTag
                    label="Date of Birth"
                    name={
                      dayjs(profile?.dateOfBirth).format("DD-MM-YYYY") || "N/A"
                    }
                  />
                  <CardNameTag label="Nationality" name={profile?.country} />
                  <CardNameTag label="State" name={profile?.state} />
                  <CardNameTag label="Address" name={profile?.address} />
                  <CardNameTag label="Suburb" name={profile?.suburb} />
                  <CardNameTag label="Postcode" name={profile?.postcode} />
                </div>
              </div>

              <div className="border-2 rounded-lg">
                <CardHeader label="Employment Details" />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3 p-4">
                  <CardNameTag
                    label="Employment Type"
                    name={profile?.employmentType}
                  />
                  <CardNameTag
                    label="Pay Rate"
                    name={profile?.payRate?.toString()}
                  />
                  <CardNameTag label="Date Joined" name={profile?.dateJoined} />
                  <CardNameTag
                    label="Salary"
                    name={profile?.salary.toLocaleString()}
                  />
                  <CardNameTag label="Level" name={profile?.level} />
                </div>
              </div>
            </div>
            <div className="border-2 rounded-lg">
              <div className="border-2 rounded-lg ">
                <CardHeader
                  label="  Emergency Contact"
                  showEditIcon
                  onEdit={() => setEmergencyModal(true)}
                />

                <div className="grid grid-cols-1  gap-2  p-4 overflow-hidden">
                  <CardNameTag label="Name" name={profile?.nextOfKin} />
                  <CardNameTag label="Phone" name={profile?.kinPhoneNumber} />
                  <CardNameTag label="City" name={profile?.kinCity} />
                  <CardNameTag
                    label="Relationship"
                    name={profile?.relationship}
                  />
                  <CardNameTag label="Country" name={profile?.kinCountry} />
                  <CardNameTag
                    label="Postal Code"
                    name={profile?.kinPostcode}
                  />
                  <CardNameTag label="Email" name={profile?.kinEmail} />
                  <CardNameTag label="State" name={profile?.kinState} />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div className="border-2 rounded-lg ">
              <CardHeader label="  Bank Information" />

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                <CardNameTag label="Bank name" name={profile?.bankName} />
                <CardNameTag
                  label="Account name"
                  name={profile?.accountName}
                />
                <CardNameTag
                  label="Account number"
                  name={profile?.accountNumber}
                />
                <CardNameTag label="Branch" name={profile?.branch} />
                <CardNameTag label="Bsb" name={profile?.bsb} />
              </div>
            </div>
            <div className="border-2 rounded-lg ">
              <CardHeader
                label="Other Information"
                showEditIcon
                onEdit={() => setOthersModal(true)}
              />

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                <CardNameTag label="Instagram" name={profile?.instagram} />
                <CardNameTag label="Linked-In" name={profile?.linkedIn} />
                <CardNameTag label="Facebook" name={profile?.facebook} />
                <CardNameTag label="Youtube" name={profile?.youtube} />
                <CardNameTag
                  label="X (Formely Twitter)"
                  name={profile?.twitter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <StaffEditComp
        personalInfoModal={personalInfoModal}
        setPersonalInfoModal={setPersonalInfoModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffEmergComp
        emergencyModal={emergencyModal}
        setEmergencyModal={setEmergencyModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffOtherComp
        othersModal={othersModal}
        setOthersModal={setOthersModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
    </>
  );
};

export default Profile;
