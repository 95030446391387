import React from 'react'

const Summary = () => {
  return (
    <div>
        <table className="w-full mb-6">
        <tbody>
          {[...Array(3)].map((_, i) => (
            <tr key={i}>
              <td className="border px-4 py-2"><input type="checkbox" className="mr-2" /> List as relevant</td>
            </tr>
          ))}
          <tr>
            <td className="border px-4 py-2 font-bold">Is a copy of the participant’s NDIS Plan attached?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="ndisPlan" className="mr-2" value="Yes" /> Yes
                </label>
                <label>
                  <input type="radio" name="ndisPlan" className="mr-2" value="No" /> No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Has the consent form to obtain and share information been signed?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="consentForm" className="mr-2" value="Yes" /> Yes
                </label>
                <label>
                  <input type="radio" name="consentForm" className="mr-2" value="No" /> No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Are there staffing requests or other requirements?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="staffingRequests" className="mr-2" value="Yes" /> Yes
                </label>
                <label>
                  <input type="radio" name="staffingRequests" className="mr-2" value="No" /> No
                </label>
              </div>
              <textarea className="w-full p-2 border border-gray-300 rounded mt-2" placeholder="If yes, please list"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Summary