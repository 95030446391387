import React, { ChangeEvent, useState, useEffect } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../label/BreadCrumb";
import CustomInput from "../../Input/CustomInput";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import Spinner from "../../Spinner";
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

interface Prediction {
  place_id: number;
  description: string;
}

type DataType = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  address: string;
  phoneNumber: string;
  agreementStart: string;
  agreementEnd: string;
  ndisNo: string;
};

const CreateClient = () => {
  const navigate = useNavigate();
  const schema: ZodType<DataType> = z.object({
    firstName: z.string().min(1, "Required Field"),
    lastName: z.string().min(1, "Required Field"),
    middleName: z.string(),
    email: z.string().email(),
    address: z.string(),
    agreementStart: z.string().min(1, "Required Field"),
    agreementEnd: z.string().min(1, "Required Field"),
    ndisNo: z.string(),
    phoneNumber: z.string().min(1, "Required Field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataType>({
    resolver: zodResolver(schema),
  });

  const [loading1, setLoading1] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const companyId = user?.companyId;

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [value, setValue] = useState<string>("");
  const [showPredictions, setShowPredictions] = useState<boolean>(true);
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);

  const handleSelect = (description: string) => {
    setValue(description);
    setShowPredictions(false);  // Hide predictions on selection
    setIsValidAddress(true);  // Address is valid as it is selected from predictions
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    getPlacePredictions({ input: evt.target.value });
    setShowPredictions(true);  // Show predictions while typing
    setIsValidAddress(false);  // Assume address is not valid until a selection is made
  };

  // Check if the input is an exact match of any prediction when user stops typing
  useEffect(() => {
    const isValid = placePredictions.some(pred => pred.description.toLowerCase() === value.toLowerCase());
    setIsValidAddress(isValid || value === "");
  }, [placePredictions, value]);

  const submitData = async (data: DataType) => {
    setLoading1(true);
    const formData = new FormData();
    formData.append("CompanyId", companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
    formData.append("FirstName", data.firstName);
    formData.append("SurName", data.lastName);
    formData.append("MiddleName", data.middleName);
    formData.append("Address", data.address);
    formData.append("Email", data.email);
    formData.append("PhoneNumber", data.phoneNumber);
    formData.append("AgreementStartDate", data.agreementStart);
    formData.append("AgreementEndDate", data.agreementEnd);
    formData.append("NDISNo", data.ndisNo);

    try {
      const { data } = await axiosInstance.post(
        `/Profiles/add_client?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      navigate(
        `/app/user/clients/${data.client.profileId}/${data.client.firstName}`
      );
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Create Client" description="Client" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-5">
        <h2 className="font-bold text-xl text-black w-auto">Add New Client</h2>
        <div className="mt-10">
          <form onSubmit={handleSubmit(submitData)}>
            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
              <CustomInput
                label="First Name"
                type="text"
                required
                placeholder="First Name"
                {...register("firstName")}
                error={errors.firstName?.message}
              />

              <CustomInput
                label="Last Name"
                type="text"
                {...register("lastName")}
                required
                placeholder="Last Name"
                error={errors.lastName?.message}
              />

              <CustomInput
                label="Middle Name"
                type="text"
                {...register("middleName")}
                placeholder="Middle Name"
                error={errors.middleName?.message}
              />
              <CustomInput
                label="Email"
                type="email"
                {...register("email")}
                required
                placeholder="Email"
                error={errors.email?.message}
              />

              <CustomInput
                label="Phone Number"
                type="text"
                {...register("phoneNumber")}
                required
                placeholder="Phone Number"
                error={errors.phoneNumber?.message}
              />

              <div className="relative">
                <CustomInput
                  label="Address"
                  type="text"
                  value={value}
                  {...register("address")}
                  onChange={handleChange}
                  placeholder="Address"
                  required
                  error={errors.address?.message}
                />
                {isPlacePredictionsLoading &&
                  <div className="absolute z-10 top-[4.5rem] bg-white">
                    <Spinner />
                  </div>
                }
                {!isPlacePredictionsLoading && showPredictions &&
                  value !== "" &&
                  (
                    <div
                      className=" flex flex-col overflow-y-auto absolute z-10 top-[4.5rem] shadow-md bg-white p-4 rounded border"
                    >
                      <ul>
                        {placePredictions.map((item: Prediction) => (
                          <li key={item.place_id} onClick={() => handleSelect(item.description)}
                            className="hover:bg-gray-100 py-2 px-1"
                          >
                            {item.description}
                          </li>
                        ))}
                        {placePredictions.length === 0 && value && !isValidAddress && (
                          <li style={{ color: 'red' }}>Invalid address</li>
                        )}
                      </ul>
                    </div>
                  )}
              </div>
              <CustomInput
                label="Agreement start date"
                type="date"
                {...register("agreementStart")}
                required
                placeholder="Agreement start date"
                error={errors.agreementStart?.message}
              />
              <CustomInput
                label="Agreement end date"
                type="date"
                {...register("agreementEnd")}
                required
                placeholder="Agreement end date"
                error={errors.agreementEnd?.message}
              />
              <div className="lg:col-span-2">
                <CustomInput
                  label="NDIS NO"
                  type="text"
                  {...register("ndisNo")}
                  required
                  placeholder="NDIS NO"
                  error={errors.ndisNo?.message}
                />
              </div>
            </div>


            <div className="mx-auto text-center mt-3 lg:col-span-2">
              <button
                type="submit"
                disabled={loading1 ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
              >
                {loading1 ? <Spinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateClient;
