import { combineReducers } from "redux";
import authSlice from "../slices/authSlice";
import adminSlice from "../slices/adminSlice";
import clientSlice from "../slices/clientSlice";
import staffSlice from "../slices/staffSlice";
import shiftRosterSlice from "../slices/shiftRosterSlice";
import DashboardSlice from "../slices/DashboardSlice";
import ticketSlice from "../slices/ticketSlice";
import documentSlice from "../slices/documentSlice";
import chartSlice from "../slices/chartSlice";
import inboxSlice from "../slices/inboxSlice";
import integrationSlice from "../slices/integrationSlice";
import timeSlice from "../slices/timeSlice";
import holidaySlice from "../slices/holidaySlice";
import formTemplateSlice from "../slices/formTemplateSlice";
import supportTypeSlice from "../slices/supportTypeSlice";
import supportScheduleSlice from "../slices/supportScheduleSlice";
import userSlice from "../slices/userSlice";
import activitySlice from "../slices/activitySlice";
import serviceProviderSlice from "../slices/serviceProviderSlice";
import careManagerSlice from "../slices/careManagerSlice";
import roleSlice from "../slices/roleSlice";
import timeSheetSlice from "../slices/timeSheetSlice";
import splittedAttendanceSlice from "../slices/splittedAttendanceSlice";
import shiftAttendanceSlice from "../slices/shiftAttendanceSlice";
import progressNoteSlice from "../slices/progressNoteSlice";
import clientScheduleSlice from "../slices/clientScheduleSlice";
import clientShiftSlice from "../slices/clientShiftSlice";
import clientProgressNoteSlice from "../slices/clientProgressNoteSlice";
import clientDailyLivingSlice from "../slices/clientDailyLivingSlice";
import clientAidAndEquipSlice from "../slices/clientAidAndEquipSlice";
import allTimeSheetSlice from "../slices/allTimeSheetSlice";
import staffTimeSheetSlice from "../slices/staffTimeSheetSlice";
import savedInvoiceSlice from "../slices/savedInvoiceSlice";
import staffShiftSlice from "../slices/staffShiftSlice";
import staffProgresNoteSlice from "../slices/staffProgresNoteSlice";
import staffAttendaceSlice from "../slices/staffAttendaceSlice";
import staffAvailabilitySlice from "../slices/staffAvailabilitySlice";
import allStaffAttendancesSlice from "../slices/allStaffAttendancesSlice";
import allAdminAttendanceSlice from "../slices/allAdminAttendanceSlice";
import userTicketSlice from "../slices/userTicketSlice";
import staffProfileSlice from "../slices/staffProfileSlice";
import refferalSlice from "../slices/refferalSlice";
import shiftReportSlice from "../slices/shiftReportSlice";
import staffShiftReportSlice from "../slices/StaffSlices/staffReportSlice";
import clientSupportSchedule from "../slices/ClientSlice/clientSupport";
import staffDailyReportSlice from "../slices/StaffSlices/staffDailyReportSlice";
import serviceProviderClientSlice from "../slices/serviceProviderClientSlice";
import clientRepresentative from "../slices/ClientSlice/clientRepresentative";
import sentMessageSlice from "../slices/sentMessageSlice";
import expenditureSlice from "../slices/expenditureSlice";
import knowledgeBaseSlice from "../slices/knowledgeBaseSlice";
import ClientShiftReportSlice from "../slices/ClientShiftReportSlice";
import clientRepSlice from "../slices/ClientSlice/clientRepSlice";

// Define the RootState type by combining all the individual state types

// Create the rootReducer with the typed RootState
const rootReducer = combineReducers({
  auth: authSlice, // Ensure that authSlice.reducer is used instead of authSlice directly
  dashboard: DashboardSlice,
  admin: adminSlice,
  client: clientSlice,
  staff: staffSlice,
  shiftRoster: shiftRosterSlice,
  ticket: ticketSlice,
  document: documentSlice,
  chart: chartSlice,
  inbox: inboxSlice,
  sent: sentMessageSlice,
  integration: integrationSlice,
  time: timeSlice,
  holiday: holidaySlice,
  formTemplate: formTemplateSlice,
  supportType: supportTypeSlice,
  supportSchedule: supportScheduleSlice,
  user: userSlice,
  activity: activitySlice,
  refferal: refferalSlice,
  serviceProvider: serviceProviderSlice,
  careManager: careManagerSlice,
  role: roleSlice,
  timeSheet: timeSheetSlice,
  splittedAttendance: splittedAttendanceSlice,
  shiftAttendance: shiftAttendanceSlice,
  progressNote: progressNoteSlice,
  clientSchedule: clientScheduleSlice,
  clientShift: clientShiftSlice,
  clientProgressNote: clientProgressNoteSlice,
  clientDailyLiving: clientDailyLivingSlice,
  clientAidAndEquip: clientAidAndEquipSlice,
  allTimeSheet: allTimeSheetSlice,
  staffTimeSheet: staffTimeSheetSlice,
  savedInvoice: savedInvoiceSlice,
  staffShift: staffShiftSlice,
  staffProgessNote: staffProgresNoteSlice,
  staffAttendance: staffAttendaceSlice,
  staffAvailability: staffAvailabilitySlice,
  allStaffAttendance: allStaffAttendancesSlice,
  allAdminAttendance: allAdminAttendanceSlice,
  userTicket: userTicketSlice,
  staffProfile: staffProfileSlice,
  shiftReport: shiftReportSlice,
  staffShiftReport: staffShiftReportSlice,
  clientSupportSchedule: clientSupportSchedule,
  staffDailyReport: staffDailyReportSlice,
  serviceProviderClient: serviceProviderClientSlice,
  clientRep: clientRepresentative,
  expenditure: expenditureSlice,
  knowledgeBase: knowledgeBaseSlice,
  clientShiftReport: ClientShiftReportSlice,
  clientReps: clientRepSlice
});

export default rootReducer;
