// import React from "react";
// // import { emptyCache } from "../hooks/cacheUtils";
// // import { AppDispatch, useAppDispatch } from "../store/store";
// // import { logout } from "../store/slices/authSlice";
// // import Cookies from "js-cookie";
// import { FallbackProps } from 'react-error-boundary';

// const ErrorComponent = (props: FallbackProps) => {
//   const { resetErrorBoundary } = props;

//   return (
//     <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
//       <div className="relative w-auto max-w-xl mx-auto">
//         {/* <div className="w-full md:w-1/3 mx-auto"> */}
//         <div className="flex flex-col p-5 rounded-lg shadow bg-white relative z-[999] ">
//           <div className="flex flex-col items-center text-center">
//             <div className="inline-block p-4 bg-secondary/20 rounded-full">
//               <svg
//                 className="w-12 h-12 fill-current text-secondary"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//               >
//                 <path d="M0 0h24v24H0V0z" fill="none" />
//                 <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
//               </svg>
//             </div>
//             <h2 className="mt-2 font-semibold text-gray-800">
//               Your session has expired
//             </h2>
//             <p className="mt-2 text-sm text-gray-600 leading-relaxed">
//               Please log in again to continue using the app.
//             </p>
//           </div>
//           <div className="flex items-center mt-3">
//             <button
//               onClick={resetErrorBoundary}
//               className="flex-1 px-4 py-2 ml-2 bg-primary hover:bg-primary/80 text-white text-sm font-medium rounded-md
//                                 transition duration-300 transform active:scale-95 ease-in-out
//                                 "
//             >
//               Continue
//             </button>
//           </div>
//         </div>
//         {/* </div> */}
//       </div>

//       <div className="fixed inset-0 z-40 bg-gray-400 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
//     </div>
//   );
// };

// export default ErrorComponent;
import React from "react";
import { emptyCache } from "../hooks/cacheUtils";
import { AppDispatch, useAppDispatch } from "../store/store";
import { logout } from "../store/slices/authSlice";
import Cookies from "js-cookie";

const ErrorComponent = () => {
  const authToken = Cookies.get("authToken");
  const dispatch: AppDispatch = useAppDispatch();
  const handleReload = () => {
    if (authToken) {
      // User object not found in local storage, navigate to the login page
      window.location.reload();
    } else {
      // User object found, reload the browser
      window.location.replace("/auth/sign-in");
      dispatch(logout());
      emptyCache();
    }
  };
  const handleLogOut = () => {
    if (!authToken) {
      // User object not found in local storage, navigate to the login page
      dispatch(logout());
      window.location.replace("/auth/sign-in");
    } else {
      // User object found, reload the browser
      window.location.replace("/auth/sign-in");
      dispatch(logout());
      emptyCache();
    }
  };

  return (
    <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-lg mx-auto">
        {/* <div className="w-full md:w-1/3 mx-auto"> */}
        <div className="flex flex-col p-5 rounded-lg shadow bg-white relative z-[999] ">
          <div className="flex flex-col items-center text-center">
            <div className="inline-block p-4 bg-secondary/20 rounded-full">
              <svg
                className="w-12 h-12 fill-current text-secondary"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
              </svg>
            </div>
            <h2 className="mt-2 font-semibold text-gray-800">
              We're sorry, but the page you're looking for is currently unavailable
            </h2>
            {/* <p className="mt-2 text-sm text-gray-600 leading-relaxed">
              Please log in again to continue using the app.
            </p> */}
          </div>
          <div className="flex items-center mt-3 flex-wrap">
            <button
              onClick={handleReload}
              className="flex-1 px-4 py-2 ml-2 bg-primary hover:bg-primary/80 text-white text-sm font-medium rounded-md
                                transition duration-300 transform active:scale-95 ease-in-out
                                "
            >
              Reload
            </button>
            <button
              onClick={handleLogOut}
              className="flex-1 px-4 py-2 ml-2 bg-red-500 hover:bg-red-400 text-white text-sm font-medium rounded-md
                                transition duration-300 transform active:scale-95 ease-in-out
                                "
            >
              Log out
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="fixed inset-0 z-40 bg-gray-400 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
    </div>
  );
};

export default ErrorComponent;
