import React, { useEffect, useState } from "react";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { DocumentList } from "./data";
import { useNavigate } from "react-router-dom";
import { fetchDocumentByName } from "../../../../services/fetchServices";
import dayjs from "dayjs";
import Spinner from "../../../../Components/Spinner";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { Modal, Tooltip, Whisper } from "rsuite";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import CustomInput from "../../../../Components/Input/CustomInput";
import { isAxiosError } from "axios";

interface DataRow {
  docName: string;
  docLink: string;
  isAvailable?: boolean;
}
interface Props {
  uid: string;
  clientName: string;
  handleTab?: (tab: string) => void;
}
interface DocumentData {
  id: string; // Unique identifier for the document
  docName: string; // Display name for the document
  docUrl?: string; // URL to the document if available
  isAvailable?: boolean;
  docDetails?: string; // Additional details or status
  implementationDate?: string; // Optional expiration date
  expirationDate?: string; // Optional expiration date
  documentName: string; // Name of the document for internal use
  documentUrl?: string; // URL of the document for internal use
  lastModified?: string;
}

interface DataRow extends DocumentData {
  // If there are additional fields needed specifically in the table, add them here
  status?: string; // Status might be a computed value or derived from docDetails
  user?: string; // User associated with the document, if any
  date?: string; // Date related to the document
}

const ClientDocument = ({ uid, clientName, handleTab }: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [filteredData, setFilteredData] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const promises = DocumentList.map((doc) =>
          fetchDocumentByName(doc.docName, Number(uid), Number(user?.companyId))
        );
        const results = await Promise.all(promises);
        const mergedData = DocumentList.map((doc, index) => ({
          id: doc.docName, // Using docName as an ID for demonstration; adjust as necessary
          docName: doc.docName,
          docLink: doc.docUrl,
          docUrl: results[index]?.documentUrl,
          isAvailable: doc.isAvailable,
          docDetails: results[index]?.documentFile || "N/A",
          implementationDate: results[index]?.implementationDate,
          expirationDate: results[index]?.expirationDate,
          documentName: doc.docName,
          documentUrl: results[index]?.documentUrl,
          lastModified: results[index]?.dateModified,
          // Add or compute any additional DataRow fields as needed
        }));
        setFilteredData(mergedData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch documents:", error);
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Document Name",
      selector: (row) => row.docName,
      sortable: true,
    },

    {
      name: "Last Modified",
      selector: (row) =>
        row.lastModified ? dayjs(row.lastModified).format("DD-MM-YYYY") : "N/A",
    },
    // {
    //     name: 'Implementation Date',
    //     selector: row => row.implementationDate ? dayjs(row.implementationDate).format('DD-MM-YYYY') : 'N/A',
    // },
    {
      name: "Expiration Date",
      selector: (row) =>
        row.expirationDate
          ? dayjs(row.expirationDate).format("DD-MM-YYYY")
          : "N/A",
    },
    {
      name: "Status",
      cell: (row) => {
        const status = getStatus(row.implementationDate, row.expirationDate);
        const statusColor = getStatusColor(status);
        return (
          <span
            className={`px-4 py-2 rounded font-semibold text-white capitalize bg-${statusColor}`}
          >
            {status}
          </span>
        );
      },
    },

    {
      name: "Actions",
      cell: (row) => (
        <span className="flex gap-4">
          <div>
            <Whisper
              placement="auto"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Preview Form</Tooltip>}
            >
              <button
                disabled={!row.isAvailable}
                onClick={() => veiwDocument(row.docLink)}
                className="flex justify-between disabled:bg-red-300 items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
              >
                <FaEye className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>
        </span>
      ),
    },
  ];

  const veiwDocument = (linkName: string) => {
    navigate(`/app/participant/${linkName}/${uid}`);
  };

  function getStatus(
    implementationDate?: string,
    expirationDate?: string
  ): string {
    if (!implementationDate && !expirationDate) {
      return "not available";
    }

    const currentDate = new Date();
    const implementation = implementationDate
      ? new Date(implementationDate)
      : null;
    const expiration = expirationDate ? new Date(expirationDate) : null;

    if (implementation && currentDate < implementation) {
      return "not started";
    } else if (expiration && currentDate > expiration) {
      return "expired";
    } else {
      return "active";
    }
  }

  function getStatusColor(status: string): string {
    switch (status) {
      case "expired":
        return "red-500";
      case "not started":
        return "gray-500";
      case "active":
        return "green-500";
      case "not available":
        return "gray-300";
      default:
        return "slate-500";
    }
  }
  const [docuName, setDocuName] = useState("");
  const [uploadModal, setUploadModal] = useState(false);

  const [docFile, setDocFile] = useState<File | null>(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [implementationDate, setImplementationDate] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setDocFile(file);
    }
  };

  const submitData = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!docFile) {
      toast.error("No File Selected");
      return;
    }
    setLoadingSubmit(true);
    const formData = new FormData();
    user && formData.append("CompanyId", user.companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
    formData.append("DocumentName", docuName);
    formData.append("ImplementationDate", implementationDate);
    formData.append("ExpirationDate", expirationDate);
    formData.append("User", clientName);
    formData.append("Status", "Pending");
    formData.append("UserRole", "Client");
    formData.append("UserId", uid);
    docFile && formData.append("DocumentFile", docFile);

    try {
      const { data } = await axiosInstance.post(
        `/Documents/add_document?userId=${user?.uid}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data?.message);
        setUploadModal(false);
        setLoadingSubmit(false);
      } else {
        toast.error(data.message);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  interface TableProps extends ExpanderComponentProps<DataRow> {
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<TableProps> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Document Name: </span> {data.docName}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-10">
      {filteredData.length <= 0 && loading && <Spinner color="#071952" />}
      <div className="flex justify-between items-center flex-wrap my-4">
        <h2 className="font-bold text-2xl text-black w-auto">
          Documents<span className="text-red-500">*</span>
        </h2>
        <button
          onClick={() => {
            // setUploadModal(true);
            setDocuName("");
            handleTab && handleTab("All Documents");
          }}
          className="px-4 py-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   "
        >
          All Documents
        </button>
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        expandableRows
        pagination={false}
        expandableRowsComponent={ExpandableRowComponent}
      />

      <Modal
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={submitData}>
              <div className="p-2 grid grid-cols-1 gap-4">
                <CustomInput
                  label="Document Name"
                  type="text"
                  value={docuName}
                  onChange={(e) => setDocuName(e.target.value)}
                  autoComplete="off"
                  required
                  minLength={2}
                />

                <CustomInput
                  label="Implementation Date"
                  type="date"
                  onChange={(e) => setImplementationDate(e.target.value)}
                  value={implementationDate}
                  required
                  autoComplete="off"
                />
                <CustomInput
                  label="Expiration Date"
                  type="date"
                  onChange={(e) => setExpirationDate(e.target.value)}
                  value={expirationDate}
                  autoComplete="off"
                />
                <CustomInput
                  label="Select Document"
                  type="file"
                  onChange={handleFileChange}
                  error={!docFile ? `Upload ${docuName} (.pdf,.doc,.docx)` : ""}
                />
              </div>
              <div className="mx-auto text-center mt-3 lg:col-span-2">
                <button
                  type="submit"
                  disabled={loadingSubmit ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loadingSubmit ? <Spinner /> : "Upload"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientDocument;
